/**
 * The current (internal) state of the schedule. The optimal flow of schedules
 * is the following: `local [=>] ack-accepted => cnf-accepted`
 */
export enum ScheduleState {
  /**
   * The schedule only exists in MEKI and has not been sent to MEK.
   * Some schedules stay in local state forever as MEKI only sends the latest
   * schedule each 15 minute window to MEK.
   */
  Local = 'local',

  /**
   * The schedule has been sent to MAVIR but no asynchronous processing happened yet.
   */
  Sent = 'sent',

  /**
   * The schedule has been sent to MAVIR but was instantly rejected. This
   * happens if the send XML does not pass the schema validation.
   */
  SentRejected = 'sent-rejected',

  /**
   * The schedule has been sent to MAVIR but we did not received any response for the HTTP request from them.
   */
  SentTimeout = 'sent-timeout',

  /**
   * ACK accepted state means the schedule has been sent to MEK and it's passed
   * the initial checks.
   */
  AckAccepted = 'ack-accepted',

  /**
   * ACK rejected state means the schedule has been sent to MEK and it was
   * rejected during the initial checks.
   *
   * This is a final state.
   *
   * __Note:__
   * This is a state where MEKI cannot recover the schedule. With proper configuration
   * MEKI will not allow sending invalid schedules to MEK so an ACK rejected
   * message always means miss-configuration by Alteo staff.
   */
  AckRejected = 'ack-rejected',

  /**
   * CNF accepted state means the schedule has been sent to MEK and after the
   * end of the 15-minute window MEK fully accepted the schedule.
   *
   * This is a final state.
   */
  CnfAccepted = 'cnf-accepted',

  /**
   * CNF partially accepted state means the schedule has been sent to MEK and
   * after the end of the 15-minute window MEK partially accepted the schedule.
   * While some time-series are accepted others has been rejected. The error
   * message contains the rejected schedules.
   *
   * This is a final state.
   *
   * __Note:__
   * This is a state handled specially, when a schedule is
   * partially accepted MEKI will merge the partially accepted schedule with the
   * latest fully accepted schedule to restore a fully functional state again.
   */
  CnfPartiallyAccepted = 'cnf-partially-accepted',

  /**
   * CNF rejected means MEK rejected the entire schedule.
   *
   * This is a final state.
   */
  CnfRejected = 'cnf-rejected',

  /**
   * This is the schedule state at MEK.
   *
   * This is a final state.
   */
  CnfVirtual = 'cnf-virtual',
}
