import { MenuItem } from '../interfaces';
import { and, oneOf, single } from '../utils';
import { Role } from '../enums';

export const MENU_ITEMS: MenuItem[] = [
  {
    path: 'hupx',
    title: 'HUPX',
    icon: 'view_in_ar',
    role: oneOf(...Object.values(Role)),
    children: [
      {
        path: 'order',
        title: 'Create order',
        icon: 'library_books',
        role: and(oneOf(Role.HupxCreateOrder, Role.HupxCreateOrderRetail), single(Role.HupxPublicOrderBooks)),
      },
      {
        path: 'new-external-order',
        title: 'Create order (ext)',
        icon: 'library_books',
        role: single(Role.HupxExternalPartner),
      },
      {
        path: 'order-validation',
        title: 'Order validation rules',
        icon: 'rule',
        role: single(Role.HupxOrderValidation),
      },
      {
        path: 'public-order',
        title: 'Public orders',
        icon: 'menu_book',
        role: single(Role.HupxPublicOrderBooks),
      },
      {
        path: 'history',
        title: 'Trade history',
        icon: 'history',
        role: single(Role.HupxTradeHistory),
      },
    ],
  },
  {
    path: 'hupx/reports',
    title: 'HUPX',
    icon: 'view_in_ar',
    role: oneOf(...Object.values(Role)),
    children: [
      {
        path: 'power-bi',
        title: 'Reports',
        icon: 'insert_chart_outlined',
        role: single(Role.PowerBi),
      },
      {
        path: 'trade-overview',
        title: 'Trade overview',
        icon: 'insert_chart_outlined',
        role: single(Role.HupxReport),
      },
    ],
  },
  {
    path: 'meki',
    title: 'MEKI',
    icon: '',
    role: oneOf(...Object.values(Role)),
    children: [
      {
        path: 'sek-schedules',
        title: 'SEK schedules',
        icon: 'energy_savings_leaf',
        role: single(Role.MekModifySchedule),
      },
      {
        path: 'meki-repair-tool',
        title: 'MEKI repair tool',
        icon: 'build',
        role: single(Role.MekRepairTool),
      },
    ],
  },
  {
    path: 'hupx-dam',
    title: 'HUPX DAM',
    icon: 'calendar_month',
    role: oneOf(...Object.values(Role)),
    children: [
      {
        path: 'limit-order',
        title: 'DAM limit order',
        icon: 'border_color',
        role: single(Role.HupxDamLimitOrder),
      },
      {
        path: 'limit-order/readonly-limit-orders',
        title: 'Readonly limit orders',
        icon: 'border_color',
        role: single(Role.HupxDamLimitOrder),
      },
      {
        path: 'deadlines/dam',
        title: 'HUPX DAM deadlines',
        icon: 'schedule',
        role: single(Role.HupxDamDeadlineConfiguration),
      },
      {
        path: 'tenant-position',
        title: 'DAM Tenant position',
        icon: 'app_registration',
        role: single(Role.HupxDamTenantPosition),
      },
      {
        path: 'allocation',
        title: 'DAM allocation',
        icon: 'edit_note',
        role: single(Role.HupxDamLimitOrder),
      },
      {
        path: 'final-limit-order',
        title: 'Final limit order',
        icon: 'border_color',
        role: and(single(Role.HupxDamFinalLimitOrder)),
      },
      {
        path: 'sek-da-schedule',
        title: 'SEK DA schedule',
        icon: 'electric_bolt',
        role: single(Role.HupxDamScheduleGeneration),
      },
    ],
  },
  {
    path: 'hupx-ida',
    title: 'HUPX IDA',
    icon: 'calendar_month',
    role: oneOf(...Object.values(Role)),
    children: [
      {
        path: 'limit-order',
        title: 'IDA limit order',
        icon: 'border_color',
        role: single(Role.HupxIdaLimitOrder),
      },
      {
        path: 'limit-order/readonly-limit-orders',
        title: 'Readonly limit orders',
        icon: 'border_color',
        role: single(Role.HupxIdaLimitOrder),
      },
      {
        path: 'deadlines/ida',
        title: 'HUPX IDA deadlines',
        icon: 'schedule',
        role: single(Role.HupxIdaDeadlineConfiguration),
      },
      {
        path: 'tenant-position',
        title: 'IDA Tenant position',
        icon: 'app_registration',
        role: single(Role.HupxIdaTenantPosition),
      },
      {
        path: 'allocation',
        title: 'IDA allocation',
        icon: 'edit_note',
        role: single(Role.HupxIdaLimitOrder),
      },
    ],
  },
  {
    path: 'forward-trades',
    title: 'Forward trades',
    icon: 'receipt_long',
    role: oneOf(...Object.values(Role)),
    children: [
      {
        path: '',
        title: 'Forward trades',
        icon: 'pending_actions',
        role: single(Role.HupxForwardTrade),
      },
    ],
  },
  {
    path: 'boss',
    title: 'BOSs',
    icon: 'description',
    role: oneOf(...Object.values(Role)),
    children: [
      {
        path: 'eda-pricing-strategy',
        title: 'EDA pricing strategy',
        icon: 'date_range',
        role: single(Role.EdaStrategy),
      },
      {
        path: 'eda-configuration',
        title: 'EDA configurations',
        icon: 'settings',
        role: single(Role.EdaConfiguration),
      },
      {
        path: 'eda-offer-report',
        title: 'EDA offer report',
        icon: 'table_chart',
        role: single(Role.EdaReport),
      },
      {
        path: 'kda-offer',
        title: 'KDA offer',
        icon: 'date_range',
        role: single(Role.KdaOffer),
      },
      {
        path: 'kda-offer-report',
        title: 'KDA offer report',
        icon: 'table_chart',
        role: single(Role.KdaReport),
      },
      {
        path: 'kda-configuration',
        title: 'KDA configurations',
        icon: 'settings',
        role: single(Role.KdaConfiguration),
      },
      {
        path: 'rir-market',
        title: 'RIR market',
        icon: 'date_range',
        role: single(Role.RirMarket),
      },
      {
        path: 'rir-offer-report',
        title: 'RIR offer report',
        icon: 'table_chart',
        role: single(Role.RirReport),
      },
      {
        path: 'rsz-tender-pricing',
        title: 'RSZ tender pricing',
        icon: 'date_range',
        role: single(Role.RszTenderPricing),
      },
      {
        path: 'rsz-tender-report',
        title: 'RSZ tender report',
        icon: 'table_chart',
        role: single(Role.RszReport),
      },
    ],
  },
  {
    path: 'eia',
    title: 'EIA',
    icon: 'description',
    role: single(Role.EiaReportSender),
    children: [
      {
        path: 'report-sender',
        title: 'EiA Report Sender',
        icon: 'outbox',
        role: single(Role.EiaReportSender),
      },
    ],
  },
  {
    path: 'workflow-parameter',
    title: 'Workflow parameters',
    icon: 'timeline',
    role: oneOf(...Object.values(Role)),
    children: [
      {
        path: '',
        title: 'Workflow parameters',
        icon: 'timeline',
        role: single(Role.WorkflowParameterWrite),
      },
      {
        path: 'labels',
        title: 'Workflow param. labels',
        icon: 'local_offer',
        role: single(Role.WorkflowParameterLabelManagement),
      },
    ],
  },
  {
    path: 'support',
    title: 'Support',
    icon: 'contact_support',
    role: single(Role.SupportCreateIncident),
    children: [
      {
        path: 'create-incident',
        title: 'New Support Request',
        icon: 'error',
        role: single(Role.SupportCreateIncident),
      },
    ],
  },
  {
    path: 'admin',
    title: 'Admin',
    icon: 'admin_panel_settings',
    role: single(Role.Administrator),
    children: [
      {
        path: 'tenants',
        title: 'Tenants',
        icon: 'groups',
        role: oneOf(Role.Administrator, Role.TenantWrite),
      },
      {
        path: 'roles',
        title: 'User permissions',
        icon: 'group_add',
        role: single(Role.Administrator),
      },
      {
        path: 'platform-api-tokens',
        title: 'Platform API tokens',
        icon: 'pin',
        role: single(Role.Administrator),
      },
      {
        path: 'service-tokens',
        title: 'Service tokens',
        icon: 'vpn_key',
        role: single(Role.Administrator),
      },
      {
        path: 'configurations',
        title: 'Configurations',
        icon: 'settings',
        role: single(Role.Administrator),
      },
    ],
  },
];
