import { calculateInBudapestTimeZone } from './calculate-in-budapest-time-zone.util';

/**
 * Calculates the start of the month of given date in Europe/Budapest timezone.
 *
 * @param referenceDate The date treated as current date. By-default it is 'now'.
 */
export function startOfMonthInBudapestTimeZone(referenceDate: Date = new Date()): Date {
  return calculateInBudapestTimeZone(referenceDate, shiftedDate => shiftedDate.startOf('month'));
}
