import { OrderEntrySide } from '../enums';

/**
 * Creates order entry key from the given side and business day.
 * Format is `${side}__${businessDay}`.
 *
 * This is used to identify an order in the
 * grouped public order book created by {@link PublicOrderBookService.getGroupedPublicOrderBookEntries}
 *
 * @param side Either `BUY` or `SELL`.
 * @param businessDay Business day in string. E.g. `2022-06-12`.
 *
 * @returns The order key, which format is `${side}__${businessDay}`.
 */
export function getOrderKey(side: OrderEntrySide, businessDay: string): string {
  return `${side}__${businessDay}`;
}
