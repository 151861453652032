import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '../shared/interfaces';
import { AadUserResponsePayload, UpdateUserOptionsResponse } from '../shared/interfaces/responses';
import { environment } from '../../environments/environment';

@Injectable()
export class AadService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Lists available AAD users.
   */
  public listUsers(): Observable<AadUserResponsePayload[]> {
    return this.httpClient
      .get<ApiResponse<AadUserResponsePayload[]>>(`${environment.apiBasePath}/v1/aad/users`)
      .pipe(map(response => response?.payload ?? []));
  }

  /**
   * Updates the options of the selected user by user ID.
   *
   * @param userId The unique ID of the user.
   * @param notificationEnabled Checks if the notifications are enabled to the selected user.
   */
  public updateUserOptions(userId: string, notificationEnabled: boolean): Observable<UpdateUserOptionsResponse> {
    return this.httpClient
      .patch<
        ApiResponse<UpdateUserOptionsResponse>
      >(`${environment.apiBasePath}/v1/aad/user/${userId}`, { notificationEnabled })
      .pipe(map(response => response.payload));
  }
}
