import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ComponentType } from '@angular/cdk/overlay';
import { Observable, tap } from 'rxjs';
import { ErrorDialogComponent } from '../modules/shared/components';

@Injectable()
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  private readonly modalQueue: {
    componentType: ComponentType<unknown>;
    config: MatDialogConfig<unknown>;
  }[] = [];

  private modalOpened = false;

  public openErrorDialog(data: unknown) {
    this.open(ErrorDialogComponent, {
      width: '800px',
      data,
      panelClass: 'app-dialog',
    });
  }

  public open<T, D = unknown, R = unknown>(
    componentType: ComponentType<T>,
    config: MatDialogConfig<D>,
    afterClosed: (result: Observable<R | undefined>) => Observable<unknown> = result => result
  ) {
    if (componentType instanceof ErrorDialogComponent) {
      if (this.modalOpened) {
        this.modalQueue.push({
          componentType,
          config,
        });
      } else {
        this.modalOpened = true;
        const resultObservable = this.dialog
          .open<T, D, R>(componentType, config)
          .afterClosed()
          .pipe(
            tap(() => {
              this.modalOpened = false;
              const nextModal = this.modalQueue.shift();
              if (nextModal !== undefined) {
                this.open(nextModal.componentType, nextModal.config);
              }
            })
          );

        afterClosed(resultObservable).subscribe();
      }
    } else {
      const resultObservable = this.dialog.open(componentType, config).afterClosed() as Observable<R | undefined>;
      afterClosed(resultObservable).subscribe();
    }
  }
}
