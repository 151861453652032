import { Component, ElementRef, Input } from '@angular/core';
import { BannerSeverity } from '../../enums';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-generic-banner',
  templateUrl: './generic-banner.component.html',
  styleUrls: ['./generic-banner.component.scss'],
})
export class GenericBannerComponent {
  /** Severity of the message. It affects the color of the banner. */
  @Input()
  public severity: BannerSeverity = BannerSeverity.Info;

  /** Indicates whether the banner is closable or not. */
  @Input()
  public closable: boolean = false;

  /** Indicates whether the banner is closable or not. If it is a standalone banner, this is undefined.  */
  @Input()
  public closeClicked$: BehaviorSubject<number | null> | undefined = undefined;

  /** The index of the message in the banner-container. If it is a standalone banner, this is undefined. */
  @Input()
  public index: number | undefined;

  constructor(private readonly elementRef: ElementRef<HTMLDivElement>) {}

  /**
   * Closes the banner. We emit the index to the subscriber banner container.
   * It will remove this banner from the array. If it is a standalone banner, we just remove the element from the DOM.
   */
  public closeBanner(index: number | undefined): void {
    if (!this.closable) {
      return;
    }

    if (index === undefined) {
      this.elementRef.nativeElement.remove();
    } else {
      this.closeClicked$?.next(index);
    }
  }
}
