import { DateTime } from 'luxon';

/**
 * Calculates the hour difference between the given two dates. It returns integer values,
 * and only whole hours are counted, which means if the difference is one milli less, then two hours, than
 * the result will be only 1.
 */
export function differenceInHours(from: Date, until: Date): number {
  const zonedFrom = DateTime.fromJSDate(from).setZone('Europe/Budapest');
  const zonedUntil = DateTime.fromJSDate(until).setZone('Europe/Budapest');

  /** To get integer number on the result, we should include the "previous" unit as well (hours -> minutes).*/
  return zonedFrom.diff(zonedUntil, ['hours', 'minutes']).get('hours');
}
