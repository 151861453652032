import { DeliveryDetails } from '../interfaces';

/**
 * Compares two delivery details by field values.
 *
 * @param firstDelivery
 * @param secondDelivery
 */
export function compareDeliveryDetails(firstDelivery?: DeliveryDetails, secondDelivery?: DeliveryDetails): boolean {
  if (!firstDelivery || !secondDelivery) {
    return false;
  }
  const { contractId: firstContractId, start: firstStart, end: firstEnd, display: firstDisplay } = firstDelivery;
  const { contractId: secondContractId, start: secondStart, end: secondEnd, display: secondDisplay } = secondDelivery;
  return (
    firstContractId === secondContractId &&
    firstStart === secondStart &&
    firstEnd === secondEnd &&
    firstDisplay === secondDisplay
  );
}
