import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleState } from '../enums';

/**
 * Converts the given schedule state enum value to a readable text.
 * If the given string is not matched by the known schedule states, then the original string is returned.
 */
@Pipe({ name: 'scheduleState' })
export class ScheduleStatePipe implements PipeTransform {
  transform(scheduleState: ScheduleState): string {
    switch (scheduleState) {
      case ScheduleState.Local:
        return 'Local';
      case ScheduleState.Sent:
        return 'Sent';
      case ScheduleState.SentRejected:
        return 'Sent rejected';
      case ScheduleState.SentTimeout:
        return 'Sent timeout';
      case ScheduleState.AckAccepted:
        return 'ACK accepted';
      case ScheduleState.AckRejected:
        return 'ACK rejected';
      case ScheduleState.CnfAccepted:
        return 'CNF accepted';
      case ScheduleState.CnfPartiallyAccepted:
        return 'CNF partially accepted';
      case ScheduleState.CnfRejected:
        return 'CNF rejected';
      case ScheduleState.CnfVirtual:
        return 'CNF virtual';
      default:
        return scheduleState;
    }
  }
}
