/**
 * Exports data as a CSV file.
 *
 * @param {string} params.fileName - The name of the CSV file to be created.
 * @param {string[]} params.headers - An array of strings representing the CSV headers.
 * @param {string[][]} params.rows - A 2D array of strings representing the CSV rows.
 *
 * @example
 * exportAsCsv({
 *   fileName: 'data',
 *   headers: ['Name', 'Age', 'City'],
 *   rows: [
 *     ['John Doe', '30', 'New York'],
 *     ['Jane Smith', '25', 'Los Angeles'],
 *     ['Mike Johnson', '35', 'Chicago']
 *   ]
 * });
 */
export function exportAsCsv(params: { fileName: string; headers: string[]; rows: string[][] }) {
  const csvHeader = params.headers.join(';');
  const csvRows = params.rows.map(row => row.join(';')).join('\n');

  const dataToExport = `${csvHeader}\n${csvRows}`;

  const blob = new Blob([dataToExport], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.download = `${params.fileName}.csv`;
  anchor.href = url;
  anchor.click();
  window.URL.revokeObjectURL(url);
}
