import { ContractInfo } from '../../interfaces/responses/contract-info.interface';
import { formatInBudapestTimeZone } from './index';

export function generateDeliveryDisplay(contractInfo: ContractInfo): string {
  const { deliveryStart, deliveryEnd, duration } = contractInfo;
  const prefix = duration === 1 ? 'H' : 'Q';
  const startTime = formatInBudapestTimeZone(new Date(deliveryStart), 'HH:mm');
  const endTime = formatInBudapestTimeZone(new Date(deliveryEnd), 'HH:mm');

  return `${prefix}${startTime}-${endTime}`;
}
