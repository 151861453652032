import { formatInBudapestTimeZone } from './index';

/**
 * Formats the given Date or string to business day string with format 'yyyy-MM-dd'.
 *
 * @param toFormat
 */
export function formatToBusinessDay(toFormat: Date | string): string {
  return formatInBudapestTimeZone(!(toFormat as Date).getTime ? new Date(toFormat) : (toFormat as Date), 'yyyy-MM-dd');
}
