import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GetTradeOverviewQueryParameters } from '../shared/interfaces/requests';
import { ApiResponse } from '../shared/interfaces';
import { TradeOverviewResponseMeta, TradeOverviewResponsePayload } from '../shared/interfaces/responses';

@Injectable()
export class ReportService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch trade overview data with the given query parameters.
   *
   * @param params filter options
   */
  public getTradeOverview(
    params: GetTradeOverviewQueryParameters
  ): Observable<ApiResponse<TradeOverviewResponsePayload[], TradeOverviewResponseMeta>> {
    return this.httpClient
      .get<ApiResponse<TradeOverviewResponsePayload[], TradeOverviewResponseMeta>>(
        `${environment.apiBasePath}/v1/report/trade-overview`,
        {
          params: {
            date: params.date,
            type: params.type,
            tenant: params.tenant,
          },
        }
      )
      .pipe(map(response => response));
  }
}
