/**
 * Returns with the converted type name.
 *
 * @param entityId The given entity ID. It is an optional parameter.
 */
export function convertToType(entityId: number | null): string {
  switch (entityId) {
    case 1:
      return 'aszk';
    case 101:
      return 'aszkm1';
    case 202:
      return 'nypazpv';
    case 203:
      return 'molfpv';
    case 204:
      return 'tmpkpv';
    default:
      return '';
  }
}
