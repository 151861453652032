/**
 * Exports data as an XLSX file.
 *
 * @param params.fileName - The name of the XLSX file to be created.
 * @param params.file - The file we want to export.
 */
export function exportAsXlsx(params: { fileName: string; file: BlobPart }) {
  const blob = new Blob([params.file], { type: 'application/vnd.ms-excel' });

  const downloadURL = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = downloadURL;
  anchor.download = `${params.fileName}.xlsx`;
  anchor.click();
  window.URL.revokeObjectURL(downloadURL);
}
