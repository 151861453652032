import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-feature-phase-banner',
  templateUrl: './feature-phase-banner.component.html',
  styleUrls: ['./feature-phase-banner.component.scss'],
})
export class FeaturePhaseBannerComponent {
  @Input()
  public type: 'alpha' | 'beta' = 'alpha';
}
