/**
 * It filters out each property from the given JsObject that is `undefined` or `null`.
 */
export function filterOutNullishProperties<T extends object>(
  params: T
): {
  [p: string]: string | number | boolean;
} {
  return Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== undefined && value !== null) as [
      string,
      string | number | boolean,
    ][]
  );
}
