import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Observable, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BossStateModuleEnum, SyncIntervalEnum } from '../../enums';

@Component({
  selector: 'app-slide-toggle-component',
  templateUrl: `./slide-toggle.component.html`,
  styleUrls: [`./slide-toggle.component.scss`],
})
export class SlideToggleComponent {
  /** Text that is shown next to the toggle. */
  @Input()
  public label!: string;

  /** State of the toggle. */
  @Input()
  public checked: boolean = false;

  /** Indicates whether the component is visible or not. */
  @Input()
  public visible: boolean = true;

  /** Indicates whether the component is read-only or not. */
  @Input()
  public disabled: boolean = false;

  /** The type of entity. There are cases where entity can be undefined. */
  @Input()
  public entity: string | undefined;

  /** Available offer sending intervals. */
  @Input()
  public syncInterval: SyncIntervalEnum = SyncIntervalEnum.QUARTER_HOURLY;

  /** Available BOSs State modules. */
  @Input()
  public module: BossStateModuleEnum = BossStateModuleEnum.EDA;

  @Input()
  public actionToCall!: Observable<void>;

  @Output()
  public state = new EventEmitter<boolean>();

  constructor(private readonly dialog: MatDialog) {}

  public changeSendingState(event: MatSlideToggleChange): void {
    if (this.disabled) {
      return;
    }

    const dialogTexts = event.checked
      ? [`Are you sure you want to enable the ${this.module} ${this.entity ?? ''} ${this.syncInterval} offer sending?`]
      : [
          `Are you sure you want to disable the ${this.module} ${this.entity ?? ''} ${
            this.syncInterval
          } offer sending?`,
        ];

    event.source.checked = !event.checked;
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          dialogTitle: `Update ${this.module} ${this.entity ?? ''} ${this.syncInterval} offer sending`,
          dialogTexts,
          actionButtonColor: 'warn',
          actionButtonText: 'Confirm',
          actionFactory: () => this.actionToCall,
        },
      })
      .afterClosed()
      .pipe(
        tap((result?: { success: boolean }) => {
          if (result?.success) {
            event.source.checked = event.checked;
            this.state.emit(event.source.checked);
          }
        })
      )
      .subscribe();
  }
}
