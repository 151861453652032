<div
  class="header"
  [ngClass]="{
    'header-primary': color === 'primary',
    'header-accent': color === 'accent',
    'header-warn': color === 'warn',
  }"
>
  <section class="title">
    <ng-content select="[name=title]"></ng-content>
  </section>
  <div class="flex-spacer"></div>
  <button *ngIf="closeable" mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="progress-container" *ngIf="loading">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<div class="content">
  <ng-content></ng-content>
</div>

<div class="footer">
  <ng-content select="[name=footer]"></ng-content>
</div>
