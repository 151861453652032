import { Injectable } from '@angular/core';
import { catchError, map, of, tap } from 'rxjs';
import { RoleCondition } from '../../../shared/interfaces';
import { UserService } from './user.service';
import { Role } from '../../../shared/enums';
import { RoleResponsePayload } from '../../../shared/interfaces/responses';
import { AuthorizationService } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private userRoles: Role[] = [];

  private readonly rolesPromise: Promise<void>;

  constructor(
    private readonly authService: AuthorizationService,
    private readonly userService: UserService
  ) {
    let promiseResolve: (value: void) => void;
    this.rolesPromise = new Promise(resolve => {
      promiseResolve = resolve;
    });

    /** Fetch roles of current user at startup, and store them forever. */
    this.authService
      .getUserRoleAssignments(this.userService.getActiveAccount().localAccountId)
      .pipe(
        map<RoleResponsePayload[], Role[]>(response => response.map(p => p.role)),
        tap(roles => {
          this.userRoles = roles;
          promiseResolve();
        }),
        catchError(() => {
          this.userRoles = [];
          promiseResolve();

          return of(undefined);
        })
      )
      .subscribe();
  }

  /**
   * Returns a promise that resolves when the current user's roles have been fetched.
   */
  public getRolesPromise(): Promise<void> {
    return this.rolesPromise;
  }

  public applyRoleCondition(roleCondition: RoleCondition): boolean {
    return roleCondition(...this.userRoles);
  }

  public getUserRoles(): Role[] {
    return this.userRoles;
  }
}
