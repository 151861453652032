<div class="component-header">
  <div class="component-header__headline">
    <h1 class="mat-headline-5">
      <ng-content select="span.view-title"></ng-content>
    </h1>
    <div class="component-header__headline__button-container">
      <ng-content select="span.view-actions"></ng-content>
    </div>
    <mat-progress-bar [style]="{ opacity: isLoading ? 1 : 0 }" mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="component-header__description">
    <p><ng-content select="span.view-description"></ng-content></p>
  </div>
</div>
