<h2 mat-dialog-title>{{ data.dialogTitle }}</h2>
<mat-dialog-content class="mat-typography toggle-container">
  <div *ngFor="let sendingState of sendingStates">
    <app-slide-toggle-component
      [module]="data.module"
      [disabled]="data.disabled"
      [checked]="sendingState.enabled"
      [syncInterval]="data.syncInterval"
      (state)="toggleHasChanged($event, sendingState)"
      [actionToCall]="data.actionToCall(sendingState.entity, !sendingState.enabled)"
      [entity]="sendingState.entity"
      [label]="data.module + ' ' + sendingState.entity + ' sync'"
    ></app-slide-toggle-component>
  </div>
</mat-dialog-content>
<app-banner-container></app-banner-container>
<mat-dialog-actions align="end">
  <button mat-button color="primary" cdkFocusInitial (click)="handleCancelAction()">
    {{ 'Close' }}
  </button>
</mat-dialog-actions>
