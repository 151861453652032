<mat-toolbar color="secondary">
  <span>
    <ng-content select="[name=header]"></ng-content>
  </span>
  <div class="main-layout-header-spacer"></div>
  <div class="header-actions">
    <ng-content select="[name=actions]"></ng-content>
  </div>
</mat-toolbar>

<mat-progress-bar [style]="{ float: 'right' }" mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<section [ngClass]="{ 'content-padding': enableContentSpacing }">
  <ng-content></ng-content>
</section>
