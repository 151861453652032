import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, switchMap, take } from 'rxjs';
import { QuantityConstraintsResponse, QuantityConstraintsResponseMeta } from '../interfaces/responses';
import { ApiResponse } from '../../../shared/interfaces';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { filterOutNullishProperties } from '../../../shared/utils';
import { TenantService } from '../../../services';
import { AllowedAuction } from '../../../shared/enums';
import { formatInBudapestTimeZone } from '../../../shared/utils/dates';

@Injectable()
export class HupxDamConstraintService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly tenantService: TenantService
  ) {}

  /**
   * Lists the quantity constraints for the given date and tenant. The quantity constraints include the sum capacity of
   * production and consumption PODs belonging to the given tenant for the given date. Furthermore, the signed summary
   * value of the active forward trades on the given date. The capacity  of each POD is constant, however the forward trade
   * value can vary by hours.
   *
   * @param params.date The target date of the constraints in the format `YYYY-MM-DD`.
   * @param params.tenant The selected tenant identifier.
   * @param [params.auction] The selected optional auction type.
   */
  public getQuantityConstraintsFor(params: {
    date: Date;
    tenant: string;
    auction?: AllowedAuction;
    detailed?: boolean;
  }): Observable<ApiResponse<QuantityConstraintsResponse, QuantityConstraintsResponseMeta>> {
    return this.httpClient.get<ApiResponse<QuantityConstraintsResponse, QuantityConstraintsResponseMeta>>(
      `${environment.apiBasePath}/v1/hupx-dam/quantity-constraints`,
      {
        params: filterOutNullishProperties({
          ...params,
          date: formatInBudapestTimeZone(params.date, 'yyyy-MM-dd'),
        }),
      }
    );
  }

  /**
   * The resulting observable emits one value a tenant to PODs capacities mapping for the target day. The mapping
   * contains an entry for each tenant which holds the sum capacity of production and consumption PODS separately.
   *
   * @param date The target day formatted as `YYYY-MM-DD`.
   */
  public getPodCapacitiesForTenants(
    date: string
  ): Observable<Map<string, { sumPodProductionCapacity: number; sumPodConsumptionCapacity: number }>> {
    return this.tenantService.tenantList.pipe(
      take(1),
      switchMap(tenantList =>
        forkJoin(
          Object.fromEntries(
            tenantList
              .filter(tenant => tenant.identifier !== 'virtual-see-all')
              .map(tenant => [
                tenant.identifier,
                this.getQuantityConstraintsFor({
                  date: new Date(date),
                  tenant: tenant.identifier,
                }).pipe(
                  map(result => ({
                    sumPodProductionCapacity: result.payload.sumPodProductionCapacity,
                    sumPodConsumptionCapacity: result.payload.sumPodConsumptionCapacity,
                  }))
                ),
              ])
          )
        )
      ),
      map(constraints => {
        const podCapacitiesForTenants = new Map<
          string,
          {
            sumPodProductionCapacity: number;
            sumPodConsumptionCapacity: number;
          }
        >();
        for (const tenant of Object.keys(constraints)) {
          podCapacitiesForTenants.set(tenant, constraints[tenant]);
        }

        return podCapacitiesForTenants;
      })
    );
  }
}
