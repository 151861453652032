<div *ngFor="let message of messages; let index = index">
  <app-generic-banner
    [index]="index"
    [closeClicked$]="closeClicked$"
    [severity]="message.options.severity ?? bannerSeverity.Info"
    [closable]="message.options.closable"
  >
    <span class="pre-line-break">{{ message.text }}</span>
  </app-generic-banner>
</div>
