<mat-table mat-table [dataSource]="schedules" class="mat-elevation-z1">
  <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{ column }}">
    <mat-header-cell mat-header-cell *matHeaderCellDef>{{ labelOf(column) }}</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">{{ getCellValue(element, 0, column) }} </mat-cell>
  </ng-container>

  <mat-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-row>
  <mat-row mat-row *matRowDef="let element; columns: displayedColumns"> </mat-row>
</mat-table>
<h3
  class="st--alert"
  *ngIf="currentPodScheduleNotExists && !insufficientFilters && schedules && schedules.length === 0"
>
  No available schedule value.
</h3>
<h3 class="st--info" *ngIf="!currentPodScheduleNotExists && insufficientFilters">
  Current schedules table is empty because of insufficient filter setting!
</h3>
