import { Role } from '../enums';

export const allOf =
  (...expectedRoles: Role[]) =>
  (...userRoles: Role[]) =>
    expectedRoles.every(role => userRoles.includes(role));

export const oneOf =
  (...expectedRoles: Role[]) =>
  (...userRoles: Role[]) =>
    expectedRoles.some(role => userRoles.includes(role));

export const single =
  (expectedRole: Role) =>
  (...userRoles: Role[]) =>
    userRoles.includes(expectedRole);

export const not =
  (roleCondition: (...userRoles: Role[]) => boolean) =>
  (...userRoles: Role[]) =>
    !roleCondition(...userRoles);

export const and =
  (...roleConditions: ((...userRoles: Role[]) => boolean)[]) =>
  (...userRoles: Role[]) =>
    roleConditions.every(roleCondition => roleCondition(...userRoles));
