import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ValidatorFn } from '@angular/forms';
import { getValidationRulesKey } from '../shared/utils';
import { OrderValidationRuleResponsePayload } from '../shared/interfaces/responses';
import { ValidationRuleOperator } from '../shared/enums';
import { HupxService } from '.';
import { greaterThanValidator, lessThanValidator } from '../shared/validators';

@Injectable()
export class OrderValidationService {
  constructor(private readonly hupxService: HupxService) {}

  /**
   * Fetch order validation rules provided by HUPX and groups them to provide
   * instant access to the available validation rules for the selected side, product and field.
   *
   * Validation rules only affects price and quantity values.
   *
   * @return Observable order validation functions grouped by
   * validation rule keys created by {@link getValidationRulesKey}.
   */
  public getGroupedOrderValidationFunctions(): Observable<Map<string, ValidatorFn[]>> {
    return this.hupxService.getOrderValidationRules().pipe(
      map((response: OrderValidationRuleResponsePayload[]) => {
        const validationRules = new Map<string, ValidatorFn[]>();
        response.forEach(({ side, prodName: product, field, operator, operand }) => {
          const key = getValidationRulesKey(side, product, field);
          const validator =
            operator === ValidationRuleOperator.GreaterThan
              ? greaterThanValidator(operand)
              : lessThanValidator(operand);
          const validators = validationRules.get(key) ?? [];
          validationRules.set(key, [...validators, validator]);
        });
        return validationRules;
      })
    );
  }
}
