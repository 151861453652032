<!-- <app-dialog-layout [closeable]="false">
  <section name="title">{{ data.title }}</section>
  <div class="confirmation-container" [innerHtml]="data.description"></div>
</app-dialog-layout> -->
<h2 mat-dialog-title>{{ data.title ? data.title : 'Delete ' + (data.resourceType || 'resource') }}</h2>
<div mat-dialog-content class="mat-typography">
  <p *ngIf="data?.description">{{ data.description }}</p>
  <p *ngIf="!data?.description">
    Are you sure you want to delete the selected {{ data.resourceType || 'resource' }}? You cant undo this action.
  </p>

  <div *ngIf="data?.resourceIdentifier?.length">
    <p>Resource details:</p>
    <div class="resource-details">
      <table>
        <tr *ngFor="let detail of data.resourceIdentifier">
          <td class="resource-details__name">{{ detail.name }}</td>
          <td class="resource-details__value">{{ detail.value }}</td>
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="data?.agreement">
    <mat-checkbox [checked]="isAgreementAccepted" color="primary" (click)="selectOrDeselect()">
      {{ data.agreement }}</mat-checkbox
    >
  </div>
</div>
<app-banner-container></app-banner-container>
<div mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial [disabled]="requestInFlight" (click)="handleCancelAction()">Cancel</button>
  <button mat-button color="warn" [disabled]="requestInFlight || !isAgreementAccepted" (click)="handleDeleteAction()">
    <span class="delete-button"
      >Delete {{ data.resourceType || '' }} <mat-spinner *ngIf="requestInFlight" [diameter]="20"></mat-spinner
    ></span>
  </button>
</div>
