import { DateTime } from 'luxon';
import { formatInBudapestTimeZone } from './format-in-budapest-time-zone.util';

/**
 * Returns a quarter-hour label of the given date in Budapest timezone.
 * E.g: `00:00-00:15`, `00:15-00:30`, ..., `20:45-21:00`, etc...
 */
export function generateQuarterHourLabelForDateInBudapestTimeZone(currentTimeBlock: Date, suffix?: string): string {
  const currentTimeBlockAsDateTime = DateTime.fromJSDate(currentTimeBlock);
  const nextTimeBlock = currentTimeBlockAsDateTime.plus({ minute: 15 });
  const currentHour = currentTimeBlockAsDateTime.setZone('Europe/Budapest').hour;
  const nextHour = nextTimeBlock.setZone('Europe/Budapest').hour;
  const currentMinutes = currentTimeBlockAsDateTime.setZone('Europe/Budapest').minute;

  return `${formatInBudapestTimeZone(currentTimeBlock, 'HH:mm')}-${formatInBudapestTimeZone(
    (currentMinutes === 45 && currentHour === nextHour ? nextTimeBlock.plus({ hour: 1 }) : nextTimeBlock).toJSDate(),
    'HH:mm'
  )}${suffix ?? ''}`;
}
