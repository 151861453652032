import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { filter, Observable, Subject, takeUntil, timer } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-auto-refresh-checkbox',
  templateUrl: './auto-refresh-checkbox.component.html',
  styleUrls: ['./auto-refresh-checkbox.component.scss'],
})
export class AutoRefreshCheckboxComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public delay = 0;

  @Input()
  public interval = 5000;

  @Input()
  public checked = true;

  @Input()
  public disabled = false;

  @Output()
  public readonly trigger = new EventEmitter<void>();

  private readonly onDestroy: Subject<boolean> = new Subject<boolean>();
  private readonly onComplete: Subject<boolean> = new Subject<boolean>();

  private timer!: Observable<number>;

  public ngOnInit(): void {
    this.timer = this.newTimer();
    this.subscribeToTimer();
  }

  public ngOnDestroy(): void {
    this.onDestroy.next(true);
    this.onDestroy.complete();
    this.onComplete.next(true);
    this.onComplete.complete();
  }

  public updateState(change: MatCheckboxChange) {
    this.checked = change.checked;
    if (this.checked) {
      this.trigger.next();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { delay, interval } = changes;
    if (delay || interval) {
      this.onComplete.next(true);
      this.timer = this.newTimer();
      this.subscribeToTimer();
    }
  }

  private newTimer(): Observable<number> {
    return timer(this.delay, this.interval).pipe(
      takeUntil(this.onComplete),
      takeUntil(this.onDestroy),
      filter(() => !this.disabled && this.checked)
    );
  }

  private subscribeToTimer() {
    this.timer.subscribe(() => this.trigger.next());
  }
}
