import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, finalize, Observable, of, tap } from 'rxjs';
import { BannerContainerComponent } from '../banner-container/banner-container.component';

/**
 * A general delete resource confirmation dialog with correct delete UX behavior.
 *
 * The delete operation happens while the modal is open and any returned error is
 * displayed by the dialog itself and allows the user to retry the operation.
 *
 * The modal returns different data based on how the user closed it:
 * - when user press cancel without attempting to delete the component will return undefined as network response
 * - when user press cancel after failed delete attempt the component will return the received error response
 * - when the user successfully deletes the resource the component will return the success response
 *
 * Its IMPORTANT that the expected `deleteActionFactory` must be correctly bound to it's source context
 * and should be able callable multiple times.
 */
@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss'],
})
export class DeleteConfirmationDialogComponent implements OnInit {
  /** Indicates if the delete request is currently in progress or not. */
  public requestInFlight = false;

  /** The response from the latest (failed) delete call. */
  public latestNetworkResponse: HttpErrorResponse | undefined;

  /** Checks if the agreement, which is necessary to deletion is accepted. */
  public isAgreementAccepted = true;

  /** General banner container used by all views. */
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  constructor(
    public dialogRef: MatDialogRef<
      DeleteConfirmationDialogComponent,
      {
        success: boolean;
        response: HttpResponse<unknown> | HttpErrorResponse | undefined;
      }
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      /** The optional title. */
      title?: string;

      /** The optional description. */
      description?: string;

      /**
       * General name of the resource in singular form.
       * This value is used in the title and in the description to refer to the resource.
       */
      resourceType: string;

      /**
       * List of details identifying the resource. This will be displayed in a list.
       */
      resourceIdentifier: { name: string; value: string }[];

      /** If this parameter is given and the agreement is accepted, the confirm button will be clickable. */
      agreement?: string;

      deleteActionFactory: () => Observable<HttpResponse<unknown>>;
    }
  ) {}

  public ngOnInit(): void {
    if (this.data.agreement) {
      this.isAgreementAccepted = false;
    }
  }

  /**
   * Attempts to delete the resource using the received `deleteActionFactory` to
   * send the network request. If the request fails the dialog is not closed but
   * an error banner is displayed and the user has option to attempt the action again.
   */
  handleDeleteAction() {
    this.bannerContainer.clearAll();

    const deleteObservable = this.data?.deleteActionFactory?.();

    /** If the provided action factory is incorrect we show error immediately */
    if (deleteObservable === undefined || deleteObservable.subscribe === undefined || !this.isAgreementAccepted) {
      this.bannerContainer.showError('Cannot delete resource.', {
        closable: false,
      });

      return;
    }

    this.requestInFlight = true;

    deleteObservable
      .pipe(
        tap(response => {
          /** Our DELETE endpoints return 204 No Content, nevertheless we return it. */
          this.dialogRef.close({ success: true, response });
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.latestNetworkResponse = errorResponse;

          this.bannerContainer.showApiError(errorResponse.error as Error, {
            closable: false,
          });

          return of(null);
        }),
        finalize(() => (this.requestInFlight = false))
      )
      .subscribe();
  }

  /**
   * Closes the dialog without executing the delete action.
   * If a failed delete action was executed prior to closing it is passed back to the caller.
   */
  handleCancelAction() {
    this.dialogRef.close({
      success: false,
      response: this.latestNetworkResponse,
    });
  }

  /** Set the agreement acceptance to selected or deselected state. */
  public selectOrDeselect(): void {
    this.isAgreementAccepted = !this.isAgreementAccepted;
  }
}
