import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable()
export class HelpDrawerService {
  private sidenav!: MatDrawer;

  public setSidenav(sidenav: MatDrawer): void {
    this.sidenav = sidenav;
  }

  public async toggle(): Promise<void> {
    if (this.sidenav !== undefined) {
      await this.sidenav.toggle();
    }
  }
}
