import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { HupxService, ReportsHupxService, TenantService } from '../../../../services';
import { TRADE_SCENARIOS } from '../../../../shared/constants';
import { OrderCustomScheduleScenario, OrderEntrySide } from '../../../../shared/enums';
import { TradeHistoryResponseMeta, TradeHistoryResponsePayload } from '../../../../shared/interfaces/responses';
import { Tenant, TradeHistoryFilterOptionsMeta } from '../../interfaces';
import { formatInBudapestTimeZone } from '../../../../shared/utils/dates';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-trade-history-table',
  templateUrl: './trade-history-table.component.html',
  styleUrls: ['./trade-history-table.component.scss'],
})
export class TradeHistoryTableComponent implements OnInit, OnChanges, OnDestroy {
  public tradeHistoryPaginationMeta: TradeHistoryResponseMeta = {
    pagination: {
      count: 0,
      offset: 0,
      limit: 200,
    },
    filterOptions: { scenarios: [], users: [], side: [], tenants: null },
  };

  public filterOptions?: TradeHistoryFilterOptionsMeta;

  public tradeHistory: TradeHistoryResponsePayload[] = [];

  public filterChange = new Subject<{
    businessDay: string;
    side: OrderEntrySide;
    scenario: OrderCustomScheduleScenario;
    offset: number;
  }>();

  public pageChange = new Subject<PageEvent>();

  public refresh = new Subject<void>();

  public readonly filterFormGroup = new FormGroup({
    businessDay: new FormControl<Date>(new Date(), { nonNullable: true, validators: Validators.required }),
    side: new FormControl<OrderEntrySide | undefined>(undefined, { nonNullable: true }),
    scenario: new FormControl<OrderCustomScheduleScenario | undefined>(undefined, { nonNullable: true }),
    collapseOrders: new FormControl<boolean>(false, { nonNullable: true }),
  });
  public readonly displayedColumns: string[] = [
    'execTime',
    'product',
    'businessDay',
    'contract',
    'side',
    'quantity',
    'price',
    'scenarioId',
    'settlementProcessingResult',
  ];
  public readonly page = new Subject<PageEvent>();

  private readonly onDestroy: Subject<boolean> = new Subject<boolean>();

  public selectedTenant?: Tenant;

  constructor(
    private readonly reportsHupxService: ReportsHupxService,
    private readonly hupxService: HupxService,
    private readonly tenantService: TenantService
  ) {}

  public ngOnInit(): void {
    this.tenantService.selectedTenant
      .pipe(
        takeUntil(this.onDestroy),
        debounceTime(200),
        filter(selectedTenant => !!selectedTenant.identifier),
        tap(selectedTenant => {
          this.selectedTenant = selectedTenant;
          this.refresh.next();
        })
      )
      .subscribe();

    combineLatest([this.refresh, this.filterFormGroup.valueChanges.pipe(startWith(this.filterFormGroup.getRawValue()))])
      .pipe(
        takeUntil(this.onDestroy),
        debounceTime(200),
        filter(() => this.filterFormGroup.valid),
        switchMap(() => {
          const { businessDay, side, scenario, collapseOrders } = this.filterFormGroup.getRawValue();

          return this.hupxService
            .ownOrderHistory({
              startDay: formatInBudapestTimeZone(businessDay, 'yyyy-MM-dd'),
              endDay: formatInBudapestTimeZone(businessDay, 'yyyy-MM-dd'),
              side,
              scenario,
              collapseOrders,
              tenant: this.selectedTenant?.identifier,
            })
            .pipe(
              tap(response => {
                this.tradeHistory = response.payload;
                this.tradeHistoryPaginationMeta = response.meta;
                this.filterOptions = response.meta.filterOptions;

                this.disableFilterIfOnlyOneOptionIsAvailable(
                  this.filterFormGroup.controls.scenario,
                  this.filterOptions?.scenarios ?? []
                );
              })
            );
        })
      )
      .subscribe();

    combineLatest([
      this.page.pipe(
        startWith({
          pageIndex: this.tradeHistoryPaginationMeta.pagination.offset,
          pageSize: this.tradeHistoryPaginationMeta.pagination.limit,
        }),
        takeUntil(this.onDestroy),
        startWith(0),
        distinctUntilChanged()
      ),
      this.filterFormGroup.valueChanges.pipe(
        takeUntil(this.onDestroy),
        debounceTime(200),
        filter(() => this.filterFormGroup.valid),
        startWith(this.filterFormGroup.value),
        /** Scenario form control can be disabled and so
         *  it's value is not present in the value change event, so
         *  we have to read the form's raw value here.
         *  */
        map(() => this.filterFormGroup.getRawValue()),
        map(values => ({
          ...values,
          businessDay: formatInBudapestTimeZone(values.businessDay, 'yyyy-MM-dd'),
        }))
      ),
    ])
      .pipe(
        takeUntil(this.onDestroy),
        tap(([offset, filerValues]) => {
          this.filterChange.next({
            offset: offset as number,
            businessDay: filerValues.businessDay,
            scenario: filerValues.scenario as OrderCustomScheduleScenario,
            side: filerValues.side as OrderEntrySide,
          });
        })
      )
      .subscribe();

    this.page.pipe(tap(pageEvent => this.pageChange.next(pageEvent))).subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const filterOptionsMetaChange = changes['filterOptionsMeta'];
    if (
      JSON.stringify(
        (filterOptionsMetaChange.previousValue as { scenarios: { name: string; value?: unknown }[] })?.scenarios
      ) !==
      JSON.stringify(
        (filterOptionsMetaChange.currentValue as { scenarios: { name: string; value?: unknown }[] })?.scenarios
      )
    ) {
      this.disableFilterIfOnlyOneOptionIsAvailable(
        this.filterFormGroup.controls.scenario,
        (filterOptionsMetaChange.currentValue as { scenarios: { name: string; value?: unknown }[] }).scenarios
      );
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }

  public labelOf(columnName: string): string {
    switch (columnName) {
      case 'execTime':
        return 'Exec time (UTC)';
      case 'product':
        return 'Product';
      case 'businessDay':
        return 'Business Day';
      case 'contract':
        return 'Contract';
      case 'side':
        return 'Side';
      case 'quantity':
        return 'Quantity (MW)';
      case 'price':
        return 'Settl. Price (EUR/MWh)';
      case 'scenarioId':
        return 'Trade Scenario';
      case 'settlementProcessingResult':
        return 'Settl. processing result';
      default:
        return columnName;
    }
  }

  public getCellValue(element: TradeHistoryResponsePayload, column: string): string | null {
    if (column === 'scenarioId') {
      return Number(element.scenarioId) >= 0 ? (TRADE_SCENARIOS.get(element.scenarioId.toString()) ?? '') : '';
    } else if (column === 'execTime') {
      return element.execTime
        ? DateTime.fromJSDate(new Date(element.execTime)).setZone('UTC').toFormat('yyyy-MM-dd HH:mm:ss')
        : null;
    } else {
      return `${element[column as keyof TradeHistoryResponsePayload] as string | number | null}`;
    }
  }

  downloadTradeHistory() {
    const { businessDay, side, scenario, collapseOrders } = this.filterFormGroup.getRawValue();
    this.reportsHupxService
      .exportOwnOrderHistoryReportXlsx({
        startDay: formatInBudapestTimeZone(businessDay, 'yyyy-MM-dd'),
        endDay: formatInBudapestTimeZone(businessDay, 'yyyy-MM-dd'),
        side,
        scenario,
        collapseOrders,
        tenant: this.selectedTenant?.identifier,
      })
      .pipe(
        tap(response => {
          const url = window.URL.createObjectURL(response);
          const anchor = document.createElement('a');
          anchor.download = `trade_history_${formatInBudapestTimeZone(new Date(), 'yyyyMMdd_HHmm')}.xlsx`;
          anchor.href = url;
          anchor.click();

          window.URL.revokeObjectURL(url);
          anchor.remove();
        })
      )
      .subscribe();
  }

  /**
   * Disables or enables filter options based on the received value of the filters from the backend.
   * This functions disables the given from control in one of the following cases:
   * - There is no available filter option.
   * - There is only one available filter option.
   * - There are two available filter options. We expect one of the filter options is
   * `{ name: 'NONE' }` with undefined `value` property.
   *
   * @param formControl Form control to update and disable.
   * @param filterOptions Available filter options for the given form control.
   */
  private disableFilterIfOnlyOneOptionIsAvailable(
    formControl: AbstractControl,
    filterOptions?: { name: string; value?: unknown }[]
  ) {
    /**
     * We look up the first filter option with `value` property.
     * This is needed because we expect there will be an option `{ name: 'NONE' }` with undefined value.
     */
    const firstWithValue = filterOptions?.find(filterOption => !!filterOption.value);

    /**
     * We only modify the form control in one of these cases:
     * - There is no available option.
     * - There is only one available option.
     * - There are two available options.
     *
     * We either update and disable the form value to the first not undefined filter option value, or
     * we update and disable the form value to the first filter option value which would be undefined in this case.
     */
    if (
      !filterOptions ||
      filterOptions.length === 0 ||
      filterOptions.length === 1 ||
      (filterOptions.length === 2 && firstWithValue)
    ) {
      if (filterOptions && filterOptions[0]) {
        formControl.setValue(firstWithValue?.value ?? filterOptions[0].value);
      }
      formControl.disable();
      formControl.updateValueAndValidity();
    }
  }
}
