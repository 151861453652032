import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

@Injectable()
export class UserService {
  constructor(private readonly msalService: MsalService) {}

  /**
   * Retrieves account of signed-in user.
   *
   * @return active account
   */
  getActiveAccount(): AccountInfo {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount) {
      /** Select first (and only one) signed in account as active account. */
      activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }

    return activeAccount;
  }
}
