import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  filter,
  finalize,
  first,
  map,
  Observable,
  of,
  skip,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { Tenant } from '../modules/shared/interfaces';
import { ApiResponse } from '../shared/interfaces';
import { ListAllTenantsResponse, TenantDetailsResponse, TenantUsersResponse } from '../shared/interfaces/responses';
import { DialogService } from './dialog.service';
import { Router } from '@angular/router';
import { AuthorizationService } from '.';

@Injectable()
export class TenantService {
  private readonly tenantsPromise: Promise<void | Error>;

  /**
   * The prefix for local storage.
   */
  private readonly localStoragePrefix = '@alteo/sinergy/tenant-identifier';
  /**
   * The list of tenants which belong to the logged in user.
   */
  public readonly tenantList = new BehaviorSubject<Tenant[]>([]);

  /**
   * The selected tenant from the list of tenants.
   */
  public readonly selectedTenant = new BehaviorSubject<Tenant>({
    name: '',
    identifier: '',
  });

  /**
   * This stream signals the user's tenant list refresh.
   */
  private readonly refreshTenantsTrigger = new Subject<void>();

  /**
   * The identifier of the selected tenant from the list of tenants.
   */
  public readonly selectedTenantIdentifier = this.selectedTenant.pipe(map(tenant => tenant.identifier));

  constructor(
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService,
    private readonly dialogService: DialogService,
    private readonly http: HttpClient
  ) {
    let promiseResolve: (value: void) => void;
    this.tenantsPromise = new Promise(resolve => {
      promiseResolve = resolve;
    });

    const cachedTenantIdentifier = localStorage.getItem(this.localStoragePrefix);
    /**
     * Fetch the tenants of the logged-in user and update selectedTenant
     * to either the first element or the tenant stored in the localStorage.
     */
    this.refreshTenantsTrigger
      .pipe(
        startWith(undefined),
        debounceTime(200),
        switchMap(() =>
          this.authorizationService.listTenantsAsUser().pipe(
            tap(tenants => {
              this.tenantList.next(tenants);

              if (tenants.length > 0) {
                const nextSelectedTenant =
                  tenants.find(tenant => tenant.identifier === cachedTenantIdentifier) ?? tenants[0];

                this.selectedTenant.next(nextSelectedTenant);
              }
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 0) {
                this.dialogService.openErrorDialog(
                  `The API server is unreachable due to no internet connection or the server not responding.`
                );
              } else {
                Sentry.captureException(error);
                this.dialogService.openErrorDialog(`Couldn't load the tenant list for user.`);
              }
              return of([]);
            })
          )
        )
      )
      .subscribe();

    /**
     * This stream handles tenant list change.
     * WHen the tenant list is empty we redirect the user to error view `no-tenant-error`.
     * First emission of the {@link tenantList} is skipped, due to it is a behavior subject,
     * and will emit an empty list by default.
     */
    this.tenantList
      .pipe(
        skip(1),
        filter(tenantList => tenantList.length === 0),
        tap(() => void this.router.navigate(['/auth/no-tenant-error']))
      )
      .subscribe();

    /**
     * Update selectedTenant in the localStorage.
     */
    this.selectedTenant
      .pipe(tap(tenant => localStorage.setItem(this.localStoragePrefix, tenant.identifier)))
      .subscribe();

    /**
     * Ensures the tenant list had fetched. First emission of the {@link selectedTenant} is skipped,
     * due to it is a behavior subject, and will emit default value.
     */
    this.selectedTenant
      .pipe(
        skip(1),
        first(),
        finalize(() => promiseResolve())
      )
      .subscribe();
  }

  /**
   * Returns a promise that resolves when the current user's tenants have been fetched.
   */
  public getTenantsPromise(): Promise<void | Error> {
    return this.tenantsPromise;
  }

  /**
   * Triggers the user's tenant list refresh.
   */
  public refreshTenants() {
    this.refreshTenantsTrigger.next(undefined);
  }

  /**
   * Lists all available tenants.
   */
  public listAllTenants(): Observable<ListAllTenantsResponse[]> {
    return this.http
      .get<ApiResponse<ListAllTenantsResponse[]>>(`${environment.apiBasePath}/v1/platform/tenants`)
      .pipe(map(response => response?.payload ?? []));
  }

  getTenantDetails(tenantIdentifier: string): Observable<TenantDetailsResponse> {
    return this.http
      .get<ApiResponse<TenantDetailsResponse>>(`${environment.apiBasePath}/v1/platform/tenants/${tenantIdentifier}`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Creates a new tenant by identifier and name.
   *
   * @param identifier The unique identifier of the tenant.
   * @param name The name of the tenant.
   */
  public createTenant(identifier: string, name: string): Observable<TenantDetailsResponse> {
    return this.http
      .post<ApiResponse<TenantDetailsResponse>>(`${environment.apiBasePath}/v1/platform/tenants`, { name, identifier })
      .pipe(map(response => response?.payload ?? []));
  }

  /**
   * Updates the selected tenant by the name.
   *
   * @param identifier The unique identifier of the tenant.
   * @param name The name of the tenant.
   */
  public updateTenant(identifier: string, name: string): Observable<void> {
    return this.http
      .patch<void>(`${environment.apiBasePath}/v1/platform/tenants/${identifier}`, { name })
      .pipe(map(response => response));
  }

  /**
   * Lists all tenant users.
   *
   * @param identifier The unique identifier of the tenant.
   */
  public listUsersForTenant(identifier: string): Observable<TenantUsersResponse[]> {
    return this.http
      .get<ApiResponse<TenantUsersResponse[]>>(`${environment.apiBasePath}/v1/platform/tenants/${identifier}/users`)
      .pipe(map(response => response?.payload ?? []));
  }

  /**
   * Deletes the selected tenant user.
   *
   * @param identifier The unique identifier of the tenant.
   * @param userId The unique GUID of the logged in user.
   */
  public deleteTenantUser(identifier: string, userId: string): Observable<void> {
    return this.http
      .delete<void>(`${environment.apiBasePath}/v1/platform/tenants/${identifier}/users/${userId}`)
      .pipe(map(response => response));
  }

  /**
   * Deletes the selected tenant POD.
   *
   * @param identifier The unique identifier of the tenant.
   * @param podCode The unique code of the POD.
   */
  public deleteTenantPod(identifier: string, podCode: string): Observable<void> {
    return this.http
      .delete<void>(`${environment.apiBasePath}/v1/platform/tenants/${identifier}/pods/${podCode}`)
      .pipe(map(response => response));
  }

  /**
   * Deletes the selected tenant partner.
   *
   * @param identifier The unique identifier of the tenant.
   * @param partnerEic The unique EIC code of the partner.
   */
  public deleteTenantPartner(identifier: string, partnerEic: string): Observable<void> {
    return this.http
      .delete<void>(`${environment.apiBasePath}/v1/platform/tenants/${identifier}/partners/${partnerEic}`)
      .pipe(map(response => response));
  }

  /**
   * Creates a new tenant user by user ID.
   *
   * @param identifier The unique identifier of the tenant.
   * @param userId The unique GUID of the logged in user.
   */
  public createTenantUser(identifier: string, userId: string): Observable<void> {
    return this.http
      .post<void>(`${environment.apiBasePath}/v1/platform/tenants/${identifier}/users`, { userId })
      .pipe(map(response => response));
  }

  /**
   * Creates a new tenant POD by POD code.
   *
   * @param identifier The unique identifier of the tenant.
   * @param podCode The unique code of the POD.
   */
  public createTenantPod(identifier: string, podCode: string): Observable<void> {
    return this.http
      .post<void>(`${environment.apiBasePath}/v1/platform/tenants/${identifier}/pods`, { podCode })
      .pipe(map(response => response));
  }

  /**
   * Creates a new tenant partner by partner EIC code.
   *
   * @param identifier The unique identifier of the tenant.
   * @param partnerEic The unique partner EIC code.
   */
  public createTenantPartner(identifier: string, partnerEic: string): Observable<void> {
    return this.http
      .post<void>(`${environment.apiBasePath}/v1/platform/tenants/${identifier}/partners`, { partnerEic })
      .pipe(map(response => response));
  }
}
