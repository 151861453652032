import {
  calculateInBudapestTimeZone,
  generateQuarterHourLabelForDateInBudapestTimeZone,
  isBefore,
  startOfNextDayInBudapestTimeZone,
} from './index';

/**
 * Returns a list of quarter-hour labels of the given date in Budapest timezone.
 * E.g: [`00:00-00:15`, `00:15-00:30`, ..., `20:45-21:00`, etc...]
 *
 * `startHour` parameter can give a hour number where the calculation starts.
 *
 * There is two special cases on DST start and DST end days.
 *
 * On DST start day, there won't be `01:45-02:00`, `02:15-02:30`, `02:30-02:45`, and `02:45-03:00`, instead there will be a `01:45-03:00` label.
 * This is ensured by the `{@link formatInTimeZone}` function.
 *
 * On DST end day, the labels `02:00-02:15`, `02:15-02:30`, `02:30-02:45`, and `02:45-03:00` are doubled
 * as the hour `02:00-03:00` is doubled as well.
 * This needs special handling as the first `02:45-03:00` label would be `02:45-02:00`,
 * which is actually logical, but not the expected `02:00-03:00`. In order to get this result an extra hour is added
 * to the next time block when the current time block and next time block hours equals and the current time block minutes
 * equal to 45.
 */
export function generateQuarterHourLabelsForDayInBudapestTimeZone(params: {
  date: Date | string;
  startHour?: number;
  suffix?: string;
}): string[] {
  let currentTimeBlock = calculateInBudapestTimeZone(new Date(params.date), shiftedDate =>
    shiftedDate.startOf('day').plus({ hours: params.startHour ?? 0 })
  );

  const startOfNextDay = startOfNextDayInBudapestTimeZone(currentTimeBlock);

  const labels = [];

  while (isBefore(currentTimeBlock, startOfNextDay)) {
    labels.push(generateQuarterHourLabelForDateInBudapestTimeZone(currentTimeBlock, params.suffix));

    currentTimeBlock = calculateInBudapestTimeZone(currentTimeBlock, shiftedDate => shiftedDate.plus({ minutes: 15 }));
  }

  return labels;
}
