<div
  class="message-box"
  [ngClass]="{
    'info-box': severity === 0,
    'warning-box': severity === 1,
    'error-box': severity === 2,
  }"
>
  <div>
    <mat-icon *ngIf="severity === 0">info</mat-icon>
    <mat-icon *ngIf="severity === 1">warning</mat-icon>
    <mat-icon *ngIf="severity === 2">error</mat-icon>
  </div>
  <p><ng-content></ng-content></p>
  <div class="spacer"></div>
  <mat-icon *ngIf="closable" (click)="closeBanner(index)" class="g-cursor-pointer">highlight_off</mat-icon>
</div>
