import { NgModule } from '@angular/core';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';

const DECLARATIONS = [MainLayoutComponent];

@NgModule({
  imports: [MaterialModule, CommonModule],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class LayoutModule {}
