import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Role } from '../shared/enums';
import { ApiResponse } from '../shared/interfaces';
import { environment } from '../../environments/environment';
import { Tenant, RoleResponsePayload } from '../shared/interfaces/responses';

@Injectable()
export class AuthorizationService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Assigns the specified role to the user.
   *
   * @param userId The Azure AD GUID of user to assign role.
   * @param rolePayload The identifier of role to assign.
   */
  public assignRoleToUser(userId: string, role: Role): Observable<void> {
    return this.httpClient
      .post<void>(`${environment.apiBasePath}/v1/authorization/user/${userId}/roles`, { role })
      .pipe(map(response => response));
  }

  /**
   * Assigns the specified role to the token.
   *
   * @param tokenId The GUID of token to assign role.
   * @param role The identifier of role to assign.
   */
  public assignRoleToToken(tokenId: string, role: Role): Observable<void> {
    return this.httpClient
      .post<void>(`${environment.apiBasePath}/v1/authorization/api-tokens/${tokenId}/roles`, { role })
      .pipe(map(response => response));
  }

  /**
   * Returns all roles defined in the system.
   */
  public listRoles(): Observable<RoleResponsePayload[]> {
    return this.httpClient
      .get<ApiResponse<RoleResponsePayload[]>>(`${environment.apiBasePath}/v1/authorization/roles`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Lists roles assigned to the specific user.
   *
   * @param userId The Azure AD GUID of user to assign role.
   */
  public getUserRoleAssignments(userId: string): Observable<RoleResponsePayload[]> {
    return this.httpClient
      .get<ApiResponse<RoleResponsePayload[]>>(`${environment.apiBasePath}/v1/authorization/user/${userId}/roles`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Lists roles assigned to the specific token.
   *
   * @param tokenId The identifier of the token.
   */
  public getTokenRoleAssignments(tokenId: string): Observable<RoleResponsePayload[]> {
    return this.httpClient
      .get<
        ApiResponse<RoleResponsePayload[]>
      >(`${environment.apiBasePath}/v1/authorization/api-tokens/${tokenId}/roles`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Lists tenants assigned to the authenticated user.
   */
  public listTenantsAsUser(): Observable<Tenant[]> {
    return this.httpClient
      .get<ApiResponse<Tenant[]>>(`${environment.apiBasePath}/v1/authorization/me/tenants`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Revokes role from user.
   *
   * @param userId ID of user.
   * @param role name of role to revoke.
   */
  public revokeRoleFromUser(userId: string, role: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiBasePath}/v1/authorization/user/${userId}/roles/${role}`)
      .pipe(map(response => response));
  }

  /**
   * Revokes role from token.
   *
   * @param tokenId ID of token.
   * @param role name of role to revoke.
   */
  public revokeRoleFromToken(tokenId: string, role: string): Observable<void> {
    return this.httpClient
      .delete<void>(`${environment.apiBasePath}/v1/authorization/api-tokens/${tokenId}/roles/${role}`)
      .pipe(map(response => response));
  }
}
