<h2 mat-dialog-title>{{ data.dialogTitle }}</h2>
<mat-dialog-content class="mat-typography">
  <div *ngFor="let text of data.dialogTexts">
    <p [innerHtml]="text"></p>
  </div>
</mat-dialog-content>
<app-banner-container></app-banner-container>
<mat-dialog-actions align="end">
  <button mat-button color="primary" cdkFocusInitial (click)="handleCancelAction()">
    {{ data.cancelButtonText === '' ? 'Cancel' : data.cancelButtonText }}
  </button>
</mat-dialog-actions>
