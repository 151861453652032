/**
 * Trade scenarios given by ALTEO.
 * They optionally restrict the allowed MEK scheduling business type and/or the target POD.
 */
export enum OrderCustomScheduleScenario {
  /** General scenario, without any constraints. */
  Custom = '0',
  /**
   * ALTEO Retail cons. This scenario is used by retail traders by default.
   */
  AlteoRetailConsumption = '1',
  /**
   * SEK ASZK POD prod.
   */
  SekAszkPodProduction = '2',
  /**
   * SEK KE POD prod.
   */
  SekKePodProduction = '3',
  /**
   * TATABÁNYA. This scenario is used by ALTEO's external partner.
   */
  Tatabanya = '4',
  /**
   * Domestic trading between the SEK and KAT users.
   */
  KatDomestic = '5',
}
