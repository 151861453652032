<span
  [class]="
    sendingStatesEnabledCount > 0
      ? sendingStatesEnabledCount === sendingStatesLength
        ? 'custom-green-badge'
        : 'custom-red-badge'
      : 'custom-grey-badge'
  "
  [matBadge]="sendingStatesEnabledCount"
></span>
