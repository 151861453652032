/** Defines the type of the report. */
export enum TradeOverviewReportType {
  /** Forward trade. */
  FWD = 'FWD',

  /** Intraday trade. */
  ID = 'ID',

  /** Day ahead market allocation. */
  DAM = 'DAM',
}
