import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { OrderCustomScheduleScenario } from '../shared/enums';
import { CashLimitsResponsePayload } from '../shared/interfaces/responses';
import { ApiResponse } from '../shared/interfaces';
import { environment } from '../../environments/environment';

@Injectable()
export class LimitsService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Returns the actual cash limit values for the given trading scenario.
   *
   * @param params Route parameters of the request.
   * @param params.scenarioId Id of the trading scenario defined by ALTEO.
   */
  public getCashLimits(params: { scenarioId: OrderCustomScheduleScenario }): Observable<CashLimitsResponsePayload> {
    return this.httpClient
      .get<ApiResponse<CashLimitsResponsePayload>>(`${environment.apiBasePath}/v1/limits/${params.scenarioId}/cash`)
      .pipe(map(response => response?.payload));
  }
}
