import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * A general information dialog.
 * There is no business related logic, it simply shows a dialog with some content and a 'cancel' button.
 */
@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<
      InformationDialogComponent,
      {
        success: boolean;
      }
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dialogTitle: string;
      dialogTexts: string[];
      cancelButtonText: string;
    }
  ) {}

  /** Closes the dialog. */
  handleCancelAction() {
    this.dialogRef.close();
  }
}
