<h2 mat-dialog-title>{{ isResultDialog ? 'Trade result' : 'Trade confirmation' }}</h2>
<mat-dialog-content>
  <div *ngIf="!isResultDialog">
    <p>Are you sure you want to send this trade?</p>
  </div>
  <div [ngClass]="stepperClass">
    <mat-stepper orientation="vertical" [@.disabled]="true" #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <ng-container *ngFor="let stepContent of stepContents; let i = index">
        <ng-template matStepperIcon="number">
          <mat-icon *ngIf="i === stepper.selectedIndex && !isSuccessTrade()">close</mat-icon>
          <mat-icon *ngIf="i === stepper.selectedIndex && isSuccessTrade()">check</mat-icon>
          <mat-icon *ngIf="i !== stepper.selectedIndex">remove</mat-icon>
        </ng-template>
        <mat-step
          ><ng-template matStepLabel>{{ stepContent.name }}</ng-template>
          <div class="error-message-container" *ngIf="!stepContent.success">
            <p>{{ stepContent.staticErrorMessage }}</p>
            <p class="trade-result__error-message" *ngIf="stepContent.errorMessage">{{ stepContent.errorMessage }}</p>
          </div></mat-step
        >
      </ng-container>
    </mat-stepper>
  </div>
</mat-dialog-content>
<app-banner-container></app-banner-container>
<mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial [disabled]="requestInFlight" mat-dialog-close>
    {{ isResultDialog ? 'Close' : 'Cancel' }}
  </button>
  <button
    *ngIf="!isResultDialog"
    mat-button
    color="primary"
    [disabled]="requestInFlight"
    (click)="handleAcceptAction()"
  >
    Confirm
    <mat-spinner *ngIf="requestInFlight" [diameter]="20"></mat-spinner>
  </button>
</mat-dialog-actions>
