import {
  calculateInBudapestTimeZone,
  differenceInHours,
  generateHourLabelInBudapestTimeZone,
  startOfDayInBudapestTimeZone,
} from './index';

/**
 * Returns a list of hour labels of the given date in Budapest timezone.
 * E.g: [`00:00-01:00`, `01:00-02:00`, etc...]
 *
 * There is two special cases on DST on and off days.
 * On DST on day, there won't be `01:00-02:00` and `02:00-03:00`, instead there will be a `01:00-03:00` label.
 * This is ensured by the `{@link formatInTimeZone}` function.
 * On DST off day, there will be two `02:00-03:00` labels.
 * This needs special handling as with {@link formatInTimeZone} the extra hour label would be `02:00-02:00`,
 * which is actually logical, but not the expected `02:00-03:00`. In order to get this result an extra hour is added
 * to the next time block when the current time block and next time block hours equals.
 */
export function generateHourLabels(date: string): string[] {
  const startOfDay = startOfDayInBudapestTimeZone(new Date(date));
  const startOfNextDay = calculateInBudapestTimeZone(startOfDay, shiftedDate => shiftedDate.plus({ days: 1 }));
  const hoursInTheDay = differenceInHours(startOfNextDay, startOfDay);

  return Array.from({ length: hoursInTheDay }).map((none, index) =>
    generateHourLabelInBudapestTimeZone(
      calculateInBudapestTimeZone(startOfDay, shiftedDate => shiftedDate.plus({ hours: index }))
    )
  );
}
