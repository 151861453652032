import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, Observable, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { HupxService } from '.';
import { DeliveryDetails, GroupedPublicOrderBookResponse } from '../modules/hupx-order/interfaces';
import { contractInfoToDeliveryDetails } from '../modules/hupx-order/utils';
import { OrderEntrySide } from '../shared/enums';
import { PublicOrderBookResponsePayload } from '../shared/interfaces/responses';
import { getOrderKey } from '../shared/utils';
import { formatToBusinessDay } from '../shared/utils/dates';

@Injectable()
export class PublicOrderBookService {
  private readonly autoRefreshTrigger = new Subject<void>();

  /** Refreshable public order book observable. */
  private readonly publicOrderBook: Observable<PublicOrderBookResponsePayload[]> = this.autoRefreshTrigger.pipe(
    startWith(undefined),
    debounceTime(200),
    switchMap(() => this.hupxService.getPublicOrderBook()),
    shareReplay(1)
  );

  constructor(private readonly hupxService: HupxService) {
    this.refreshPublicOrderBook();
  }

  /**
   * Refreshes the grouped public order book entries returned by the {@link getGroupedPublicOrderBookEntries} function.
   */
  public refreshPublicOrderBook() {
    this.autoRefreshTrigger.next();
  }

  public getPublicOrderBook(): Observable<PublicOrderBookResponsePayload[]> {
    return this.publicOrderBook;
  }

  /**
   * Fetches the public order book from HUPX and post process the response to simplify filtering.
   *
   * Every public order book entry will be converted into one or two delivery details object
   * whether it contains any sell or buy order. We assign every created delivery details object
   * to a group formed by their side and the business day they are available for.
   *
   * Group key is created by {@link getOrderKey}.
   *
   * @return Observable of grouped public order books entries and grouped product names.
   */
  public getGroupedPublicOrderBookEntries(): Observable<{
    groupedOrders: Map<string, DeliveryDetails[]>;
    groupedProductNames: { buy: Set<string>; sell: Set<string> };
  }> {
    return this.publicOrderBook.pipe(
      distinctUntilChanged((previous, current) => {
        const previousContracts = previous.map(entry => entry.contractId);
        /** Filter out events where no contract is added to or removed from the received Public Order Book */
        return (
          previous.length === current.length && current.every(entry => previousContracts.includes(entry.contractId))
        );
      }),
      map(payload =>
        payload.reduce(
          (groupedResponse: GroupedPublicOrderBookResponse, entry) => {
            const { groupedOrders, groupedProductNames } = groupedResponse;
            const businessDay = formatToBusinessDay(entry.contractInfo.deliveryStart);
            const deliveryDetails = contractInfoToDeliveryDetails(entry.contractId, entry.contractInfo);

            /** We are only interested in products with quarter-hourly or hourly duration. */
            if (deliveryDetails.duration !== 0.25 && deliveryDetails.duration !== 1) {
              return groupedResponse;
            }

            /** We add the delivery details to BUY side if it contains any SELL order. */
            if (entry.sell.length > 0) {
              const orderKey = getOrderKey(OrderEntrySide.Buy, businessDay);
              const deliveryDetailses = groupedOrders.get(orderKey) ?? [];
              deliveryDetailses.push(deliveryDetails);
              groupedOrders.set(orderKey, deliveryDetailses);
              groupedProductNames.buy.add(entry.contractInfo.product);
            }

            /** We add the delivery details to SELL side if it contains any BUY order. */
            if (entry.buy.length > 0) {
              const orderKey = getOrderKey(OrderEntrySide.Sell, businessDay);
              const deliveryDetailses = groupedOrders.get(orderKey) ?? [];
              deliveryDetailses.push(deliveryDetails);
              groupedOrders.set(orderKey, deliveryDetailses);
              groupedProductNames.sell.add(entry.contractInfo.product);
            }

            return groupedResponse;
          },
          {
            groupedOrders: new Map<string, DeliveryDetails[]>(),
            groupedProductNames: {
              buy: new Set<string>(),
              sell: new Set<string>(),
            },
          } as GroupedPublicOrderBookResponse
        )
      )
    );
  }
}
