<div class="tht__table-header">
  <form class="tht__filter-form" [formGroup]="filterFormGroup">
    <mat-form-field>
      <input matInput [matDatepicker]="businessDayPicker" formControlName="businessDay" />
      <mat-hint>YYYY-MM-DD</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="businessDayPicker"></mat-datepicker-toggle>
      <mat-datepicker #businessDayPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Side" formControlName="side">
        <mat-option *ngFor="let sideOption of filterOptions?.side" [value]="sideOption.value">{{
          sideOption.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Trade scenario" formControlName="scenario">
        <mat-option *ngFor="let scenarioOption of filterOptions?.scenarios" [value]="scenarioOption.value">{{
          scenarioOption.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox formControlName="collapseOrders" color="primary">Summarised trades</mat-checkbox>
  </form>
  <div class="spacer"></div>
  <div class="tht__table-actions">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!this.filterFormGroup.valid"
      (click)="refresh.next(undefined)"
    >
      <mat-icon>refresh</mat-icon>
      Refresh
    </button>
    <button mat-raised-button color="primary" [disabled]="!this.filterFormGroup.valid" (click)="downloadTradeHistory()">
      Download
    </button>
  </div>
</div>

<div class="mat-elevation-z1">
  <mat-table mat-table [dataSource]="tradeHistory">
    <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{ column }}">
      <mat-header-cell mat-header-cell *matHeaderCellDef>{{ labelOf(column) }}</mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let element"
        [class.tht__sell-row]="element['side'] === 'SELL'"
        [class.tht__buy-row]="element['side'] === 'BUY'"
        >{{ getCellValue(element, column) }}</mat-cell
      >
    </ng-container>
    <mat-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-row>
    <mat-row mat-row *matRowDef="let element; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [length]="tradeHistoryPaginationMeta.pagination.count"
    [pageSize]="tradeHistoryPaginationMeta.pagination.limit"
    showFirstLastButtons
    (page)="page.next($event)"
  ></mat-paginator>
</div>
