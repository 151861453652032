<div
  class="message-box"
  [ngClass]="{
    'alpha-box': type === 'alpha',
    'beta-box': type === 'beta',
  }"
>
  <ng-container *ngIf="type === 'alpha'">
    <mat-icon>build</mat-icon>
    <div class="text-body">
      <p class="header">Alpha feature</p>
      <p>This feature is under active development and should be used only for testing purposes!</p>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'beta'">
    <mat-icon>build</mat-icon>
    <div class="text-body">
      <p class="header">Beta feature</p>
      <p>This feature is in public beta and may have some unexpected rough edges, use with caution.</p>
    </div>
  </ng-container>
</div>
