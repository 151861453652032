import { Pipe, PipeTransform } from '@angular/core';
import { OrderEntrySide } from '../../../shared/enums';

/**
 * Converts the given schedule type enum value to talkative definitions.
 * It maps:
 * - A01 -> Production
 * - A02 -> Domestic trade
 * - A04 -> Consumption
 *
 * If the given string is not matched by the known schedule types, then the original string is returned.
 */
@Pipe({ name: 'tradeDirectionDisplay' })
export class TradeDirectionDisplayPipe implements PipeTransform {
  transform(orderEntrySide: OrderEntrySide): string {
    switch (orderEntrySide) {
      case OrderEntrySide.Sell:
        return 'Sell';
      case OrderEntrySide.Buy:
        return 'Buy';
      default:
        return orderEntrySide;
    }
  }
}
