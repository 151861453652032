export const environment = {
  enableAngularProdMode: true,
  reportErrors: true,
  environment: 'production',
  frontendVersion: '0.62.1 (from v0.62.1 at 7243f80)',
  /** These settings come from the main (production) Sinergy tenant in Azure. */
  azureADTenantID: '15833e6a-b99b-4bb8-b02a-d4ae48258b0c',
  azureADClientID: '273a5cb3-b622-4523-afaa-f25345dd291c',
  azureADApiAppURI: 'api://alteo-sinergy-platform-api',
  apiBasePath: 'https://api.platform.production.artemis-energy.com',
  pagination: {
    defaultPageLimit: 50,
  },
};
