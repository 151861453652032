<app-dialog-layout color="warn">
  <span name="title">Unhandled error</span>

  <h3>An unhandled error happened. If the error persists, please contact the support team.</h3>

  <details>
    <summary>Show details</summary>
    <p>
      <code class="error-dialog__details">{{ content }}</code>
    </p>
  </details>
  <section name="footer" class="error-dialog__footer">
    <button mat-flat-button color="primary" (click)="closeDialog()">Close</button>
    <button mat-flat-button (click)="reloadPage()">Reload</button>
  </section>
</app-dialog-layout>
