import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Less than validator. Value must be less than the given number.
 *
 * @param maxValue - The maximum value that the input must be less than or equal to.
 * @param inclusive - Whether the validation should be inclusive (default) or exclusive.
 * @param errorMessage - The error message to show below the form when invalid. Optional.
 */
export function lessThanValidator(maxValue: number, inclusive: boolean = true, errorMessage?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value as number;

    const inclusiveCheck = inclusive && value > maxValue;
    const exclusiveCheck = !inclusive && value >= maxValue;

    if (value && (inclusiveCheck || exclusiveCheck)) {
      return {
        lessThanInvalid: {
          lessThan: maxValue,
          errorMessage,
        },
      };
    }

    return null;
  };
}
