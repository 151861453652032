import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderEntryResponsePayload, OrderExecutionReport } from '../../../../shared/interfaces/responses';
import { MatStepper } from '@angular/material/stepper';
import { Observable, catchError, finalize, of, tap } from 'rxjs';
import { BannerContainerComponent } from '../banner-container/banner-container.component';
import { ApiResponse } from '../../../../shared/interfaces';

@Component({
  templateUrl: './trade-result-dialog.component.html',
  styleUrls: ['./trade-result-dialog.component.scss'],
})
export class TradeResultDialogComponent {
  /**
   * The stepper object which is used for error handling.
   */
  @ViewChild('stepper', { static: true }) public stepper!: MatStepper;

  /** General banner container used by all views. */
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  /** Indicates if the request is currently in progress or not. */
  public requestInFlight = false;

  /**
   * Checks if the view shows the result of the trade.
   */
  public isResultDialog = false;

  /**
   * The class name of the stepper.
   */
  public stepperClass = 'initialized-stepper';

  public stepContents: { name: string; success: boolean; staticErrorMessage: string; errorMessage?: string }[] = [
    {
      name: 'Trade validation',
      success: true,
      staticErrorMessage: 'Order entry payload validation failed with the following error:',
    },
    {
      name: 'MEK validation',
      success: true,
      staticErrorMessage: 'The generated schedule changes have been rejected with the following error:',
    },
    {
      name: 'Trade execution',
      success: false,
      staticErrorMessage: 'Sending order entry to HUPX failed.',
    },
    {
      name: 'MEK execution',
      success: false,
      staticErrorMessage:
        'The trade executed successfully but the matching calls to MEK have failed. This requires manual intervention, please contact the Artemis support team.',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { actionFactory: () => Observable<ApiResponse<OrderEntryResponsePayload>> }
  ) {}

  public handleAcceptAction(): void {
    this.bannerContainer.clearAll();

    const actionObservable = this.data?.actionFactory?.();

    /** If the provided action factory is incorrect we show error immediately */
    if (actionObservable === undefined || actionObservable.subscribe === undefined) {
      this.bannerContainer.showError('Cannot call resource.', {
        closable: false,
      });

      return;
    }

    this.requestInFlight = true;

    actionObservable
      .pipe(
        tap(response => {
          this.isResultDialog = true;
          this.handleResponse(response);
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          if (
            ['CashLimitValidationError', 'MekiScheduleValidationError'].includes((errorResponse.error as Error).name)
          ) {
            this.isResultDialog = true;
            this.handleResponse(errorResponse);
          } else {
            this.bannerContainer.showApiError(errorResponse.error as Error, {
              closable: false,
            });
          }

          return of(null);
        }),
        finalize(() => (this.requestInFlight = false))
      )
      .subscribe();
  }

  /**
   * Handles the response of the send and calculates the state of the stepper.
   *
   * @param response The response of the send function.
   */
  public handleResponse(response: { payload: OrderEntryResponsePayload } | HttpErrorResponse): void {
    const { status, payload } = response as { status: number; payload: OrderEntryResponsePayload };

    if (status === undefined) {
      this.stepContents[2].success = !!payload?.ordrExeRprt?.orders?.find(
        (order: OrderExecutionReport) => order.action === 'FEXE'
      );
      this.stepContents[3].success = !payload?.failedMekiCalls || payload?.failedMekiCalls?.length === 0;
    } else if (status === 403 && ((response as HttpErrorResponse).error as Error).name === 'CashLimitValidationError') {
      this.stepContents[0].success = false;
      this.stepContents[0].errorMessage = this.getErrorMessage(response as HttpErrorResponse);
    } else if (
      status === 400 &&
      ((response as HttpErrorResponse).error as Error).name === 'MekiScheduleValidationError'
    ) {
      this.stepContents[1].success = false;
      this.stepContents[1].errorMessage = this.getErrorMessage(response as HttpErrorResponse);
    }

    let hasError = false;

    this.stepContents.forEach(stepContent => {
      if (stepContent.success && !hasError) {
        this.stepper.next();
      } else {
        hasError = true;
      }
    });

    this.isSuccessTrade();
  }

  /**
   * Checks if the trade was successful.
   */
  public isSuccessTrade(): boolean {
    if (this.stepContents.every(stepContent => stepContent.success)) {
      this.stepperClass = 'successful-stepper';

      return true;
    } else {
      this.stepperClass = 'failed-stepper';

      return false;
    }
  }

  /**
   * Gets the error message from the error response.
   */
  public getErrorMessage(response: HttpErrorResponse): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (response.error as Error).message ?? 'Unknown error';
  }
}
