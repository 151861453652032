import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, finalize, Observable, of, tap } from 'rxjs';
import { BannerContainerComponent } from '../banner-container/banner-container.component';

/**
 * A general confirmation dialog with correct UX behavior.
 *
 * The operation happens while the modal is open and any returned error is
 * displayed by the dialog itself and allows the user to retry the operation.
 *
 * The modal returns different data based on how the user closed it:
 * - when the user presses cancel without attempting to call the passed action, the component will return undefined as network response
 * - when the user presses cancel after a failed call attempt, the component will return the received error response
 * - when the user successfully calls the action, the component will return the success response
 *
 * Its IMPORTANT that the expected `actionFactory` must be correctly bound to its source context
 * and should be callable multiple times.
 */
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  /** Indicates if the request is currently in progress or not. */
  public requestInFlight = false;

  /** The response from the latest (failed) call. */
  public latestNetworkResponse: HttpErrorResponse | undefined;

  /** Checks if the confirmation is accepted. */
  public isAccepted = true;

  /** General banner container used by all views. */
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  constructor(
    public dialogRef: MatDialogRef<
      ConfirmationDialogComponent,
      {
        success: boolean;
        response: HttpResponse<unknown> | HttpErrorResponse | undefined;
      }
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dialogTitle: string;
      dialogTexts: string[];
      actionButtonColor: 'warn' | 'primary' | 'accent';
      actionButtonText: string;
      cancelButtonText?: string;
      dialogQuestion?: string;
      acceptance?: string;

      actionFactory: () => Observable<HttpResponse<unknown>>;
    }
  ) {}

  public ngOnInit(): void {
    if (this.data.acceptance) {
      this.isAccepted = false;
    }
  }

  /**
   * Attempts to delete the resource using the received `deleteActionFactory` to
   * send the network request. If the request fails the dialog is not closed but
   * an error banner is displayed and the user has option to attempt the action again.
   */
  handleAcceptAction() {
    this.bannerContainer.clearAll();

    const actionObservable = this.data?.actionFactory?.();

    /** If the provided action factory is incorrect we show error immediately */
    if (actionObservable === undefined || actionObservable.subscribe === undefined || !this.isAccepted) {
      this.bannerContainer.showError('Cannot call resource.', {
        closable: false,
      });

      return;
    }

    this.requestInFlight = true;

    actionObservable
      .pipe(
        tap(response => {
          /** We return the response of the action request. */
          this.dialogRef.close({ success: true, response });
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.latestNetworkResponse = errorResponse;

          this.bannerContainer.showApiError(errorResponse.error as Error, {
            closable: false,
          });

          return of(null);
        }),
        finalize(() => (this.requestInFlight = false))
      )
      .subscribe();
  }

  /**
   * Closes the dialog without executing the action.
   * If a failed action was executed prior to closing it is passed back to the caller.
   */
  handleCancelAction() {
    this.dialogRef.close({
      success: false,
      response: this.latestNetworkResponse,
    });
  }

  /** Set the acceptance to selected or deselected state. */
  public selectOrDeselect(): void {
    this.isAccepted = !this.isAccepted;
  }
}
