import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Number format validator with given maximum decimal places.
 */
export function numberValidator(decimalPlaces: number, allowZero = true, errorMsg?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const regExp = new RegExp(`^(-[0-9])?[0-9]*(\\.[0-9]{0,${decimalPlaces}})?$`);

    if (control.value === '' || control.value === null || control.value === undefined) {
      return null;
    }

    const value = (control.value as string | null | undefined)?.toString();

    if (!value?.match(regExp) || (!allowZero && value && Number(value) <= 0)) {
      return {
        numberInvalid: {
          errorMsg: errorMsg ? errorMsg : `Value must be a number with at most ${decimalPlaces} decimal place(s).`,
        },
      };
    }

    return null;
  };
}
