import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-component-header',
  templateUrl: 'view-component-header.component.html',
  styleUrls: ['./view-component-header.component.scss'],
})
export class ViewHeaderComponent {
  @Input()
  public isLoading = false;
}

// TODO: Wrap this into the default view.
