/**
 * Returns DeliveryDetails for the given ContractInfo.
 *
 * @param contractId
 * @param contractInfo
 */
import { ContractInfo } from '../../../shared/interfaces/responses/contract-info.interface';
import { DeliveryDetails } from '../interfaces';
import { formatInBudapestTimeZone } from '../../../shared/utils/dates';

export function contractInfoToDeliveryDetails(
  contractId: number,
  { deliveryStart, deliveryEnd, duration, product }: ContractInfo
): DeliveryDetails {
  const prefix = duration === 1 ? 'H' : 'Q';
  const startTime = formatInBudapestTimeZone(new Date(deliveryStart), 'HH:mm');
  const endTime = formatInBudapestTimeZone(new Date(deliveryEnd), 'HH:mm');

  return {
    contractId,
    duration,
    product,
    start: formatInBudapestTimeZone(new Date(deliveryStart), 'yyyy-MM-dd HH:mm'),
    end: formatInBudapestTimeZone(new Date(deliveryEnd), 'yyyy-MM-dd HH:mm'),
    display: `${prefix}${startTime}-${endTime}`,
  };
}
