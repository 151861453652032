<h2 mat-dialog-title>{{ data.dialogTitle }}</h2>
<mat-dialog-content class="mat-typography">
  <div *ngFor="let text of data.dialogTexts">
    <p>{{ text }}</p>
  </div>
  <p class="dialog-question" *ngIf="data.dialogQuestion">{{ data.dialogQuestion }}</p>
  <div *ngIf="data?.acceptance">
    <mat-checkbox [checked]="isAccepted" color="primary" (click)="selectOrDeselect()">
      {{ data.acceptance }}</mat-checkbox
    >
  </div>
</mat-dialog-content>
<app-banner-container></app-banner-container>
<mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial [disabled]="requestInFlight" (click)="handleCancelAction()">
    {{ data.cancelButtonText ?? 'Cancel' }}
  </button>
  <button
    mat-button
    [color]="data.actionButtonColor"
    [disabled]="requestInFlight || !isAccepted"
    (click)="handleAcceptAction()"
  >
    <span class="submit-button">
      {{ data.actionButtonText }}
      <mat-spinner *ngIf="requestInFlight" [diameter]="20"></mat-spinner>
    </span>
  </button>
</mat-dialog-actions>
