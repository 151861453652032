import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, distinctUntilChanged, map, of, switchMap, tap, timer } from 'rxjs';
import { ConfigurationService } from '../../../services';

@Injectable({ providedIn: 'root' })
export class MaintenanceService {
  /**
   * The interval to check if we are in maintenance mode or not in milliseconds.
   */
  private readonly checkInterval = 30_000;

  /**
   * If the maintenance mode is `true`: maintenance banner will appear.
   * If the maintenance mode is `false`: maintenance banner will disappear.
   */
  public maintenanceMode = new BehaviorSubject<boolean>(false);

  constructor(private readonly configurationService: ConfigurationService) {
    timer(0, this.checkInterval)
      .pipe(
        switchMap(() =>
          this.configurationService.maintenanceMode().pipe(
            map(response => response.maintenanceMode),
            /**
             * We do not want to confuse user and prevent them reporting issues when our API is unreachable.
             * However we do want to catch the API error here and handle it, so the service keeps checking for
             * the maintenance status
             */
            catchError(() => of(false))
          )
        ),
        distinctUntilChanged(),
        tap(maintenanceMode => this.maintenanceMode.next(!!maintenanceMode))
      )
      .subscribe();
  }
}
