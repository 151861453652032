/**
 * The business type of the schedule.
 * Each schedule has one of four types: A01, A02, A03, A04.
 * However internally we don't handle A03 (external trade) as Alteo doesn't use it.
 */
export enum ScheduleType {
  /** Production */
  A01 = 'A01',

  /** Domestic trade */
  A02 = 'A02',

  /** Consumption */
  A04 = 'A04',
}
