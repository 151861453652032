/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '../shared/interfaces';
import { environment } from '../../environments/environment';
import {
  ConfigurationResponseMeta,
  ConfigurationResponsePayload,
  HealthCheckResponsePayload,
  ModifyConfigurationPayload,
} from '../shared/interfaces/responses';
import { ConfigurationQueryParameters } from '../shared/interfaces/requests';
import { filterOutNullishProperties } from '../shared/utils';

@Injectable()
export class ConfigurationService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * This endpoint returns if the maintenance mode is on.
   */
  public maintenanceMode(): Observable<{ maintenanceMode: boolean }> {
    return this.httpClient
      .get<ApiResponse<{ maintenanceMode: boolean }>>(`${environment.apiBasePath}/v1/platform/maintenance-mode`)
      .pipe(map(response => response?.payload));
  }

  /**
   * This endpoint returns the entire or sub-set of the configuration object.
   * Optionally the definition of each configuration can be returned (this is mainly used to render the option editor on the UI).
   *
   * @param params Describes configuration filter parameters.
   * @returns Configuration values filtered by the given parameters.
   */
  public getConfiguration(
    params: ConfigurationQueryParameters
  ): Observable<ApiResponse<ConfigurationResponsePayload, ConfigurationResponseMeta>> {
    return this.httpClient.get<ApiResponse<ConfigurationResponsePayload, ConfigurationResponseMeta>>(
      `${environment.apiBasePath}/v1/platform/configuration`,
      {
        params: filterOutNullishProperties({
          name: params.name,
          include_definition: params.includeDefinition,
        }),
      }
    );
  }

  /**
   * This endpoint partially updates the configuration with the specified payload.
   * The authenticated user email is saved in the update_by column during an update.
   *
   * @param configuration Partial configuration object.
   * @returns The full configuration response with default call parameters.
   */
  public modifyConfiguration(configuration: ModifyConfigurationPayload): Observable<ConfigurationResponsePayload> {
    return this.httpClient.patch<ConfigurationResponsePayload>(`${environment.apiBasePath}/v1/platform/configuration`, {
      ...configuration,
    });
  }

  /**
   * This endpoint gets the current API health check.
   * It contains the application version, the deployed environment and the start time.
   */
  public getHealthCheck(): Observable<HealthCheckResponsePayload> {
    return this.httpClient
      .get<ApiResponse<HealthCheckResponsePayload>>(`${environment.apiBasePath}/v1/health-check`)
      .pipe(map(response => response?.payload));
  }
}
