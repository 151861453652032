import { OrderEntrySide } from '../enums';

/**
 * Creates validation rule key from the given side, product name and fieldName.
 * Format is `${side}__${product}__${fieldName}`.
 *
 * @param side Either `BUY` or `SELL`.
 * @param product Product name. E.g. `XBID_Hour_Power`.
 * @param fieldName Validation rule field name. (e.g.: `px`, `qty`).
 *
 * @return The validation rules key, which format is `${side}__${product}__${fieldName}`.
 */
export function getValidationRulesKey(side: OrderEntrySide, product: string, fieldName: string): string {
  return `${side}__${product}__${fieldName}`;
}
