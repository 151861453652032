import { DateTime } from 'luxon';

/**
 * Executes the given date manipulation on the given date in `Europe/Budapest` time zone and returns the result in UTC.
 *
 * @param date The target date.
 * @param calculation The date manipulation function to execute.
 */
export function calculateInBudapestTimeZone(date: Date, calculation: (shiftedDate: DateTime) => DateTime): Date {
  const shiftedDate = DateTime.fromJSDate(date).setZone('Europe/Budapest');
  const shiftedResult = calculation(shiftedDate);

  return shiftedResult.toUTC().toJSDate();
}
