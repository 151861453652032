import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, catchError, debounceTime, finalize, map, of, takeUntil, tap } from 'rxjs';
import { getErrorMessage } from '../../../../shared/utils';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BannerContainerComponent } from '../banner-container/banner-container.component';
import { AssignValueOption } from '../../interfaces';

/**
 * A general value assign dialog with correct assign UX behavior.
 *
 * The assign operation happens while the modal is open and any returned error is
 * displayed by the dialog itself and allows the user to retry the operation.
 *
 * The modal returns different data based on how the user closed it:
 * - when the user presses cancel without attempting to call the passed action, the component will return undefined as network response
 * - when the user presses cancel after a failed call attempt, the component will return the received error response
 * - when the user successfully calls the action, the component will return the success response
 *
 * Its IMPORTANT that the expected `assignActionFactory` must be correctly bound to it's source context
 * and should be able callable multiple times.
 */
@Component({
  selector: 'app-assign-value-dialog',
  templateUrl: './assign-value-dialog.component.html',
  styleUrls: ['./assign-value-dialog.component.scss'],
})
export class AssignValueDialogComponent implements OnInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();

  /**
   * Indicates if the assign request is currently in progress or not.
   */
  public requestInFlight = false;

  /**
   * General banner container used by all views.
   */
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  /**
   * The available options to assign a value.
   */
  public options: Map<string, AssignValueOption> = new Map();

  /**
   * The form control which contains the selected option.
   */
  public readonly valueControl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required],
  });

  /**
   * The filtered options by typed string.
   */
  public filteredOptions!: Observable<AssignValueOption[]>;

  /** The response from the latest (failed) delete call. */
  public latestNetworkResponse: HttpErrorResponse | undefined;

  /**
   * Contains all required error messages.
   */
  public getErrorMessage = getErrorMessage;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      /**
       * The function lists the available options.
       */
      optionsFactory: () => Observable<Map<string, AssignValueOption>>;

      /**
       * The optional title of the modal window. Without it, there is a default title.
       */
      title?: string;

      /**
       * The optional description of the modal window. Without it, there is a default description.
       */
      description?: string;

      /**
       * The optional input label of the modal window. Without it, there is a default input label.
       */
      label?: string;

      /**
       * The validator of the select list.
       */
      validator: (options: Map<string, AssignValueOption>) => ValidatorFn;

      /**
       * The dynamic assign function.
       *
       * @param value The value we want to assign.
       */
      assignActionFactory: (value: string) => Observable<HttpResponse<unknown>>;
    },
    public dialogRef: MatDialogRef<AssignValueDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.requestInFlight = true;

    this.data
      .optionsFactory()
      .pipe(
        tap(options => {
          this.options = options;
          this.valueControl.addValidators(this.data.validator(this.options));
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.latestNetworkResponse = errorResponse;

          this.bannerContainer.showApiError(errorResponse.error as Error, {
            closable: false,
          });

          return of(null);
        }),
        finalize(() => (this.requestInFlight = false))
      )
      .subscribe();

    /**
     * Fills the filtered options using a typed character string.
     */
    this.filteredOptions = this.valueControl.valueChanges.pipe(
      takeUntil(this.onDestroy),
      debounceTime(200),
      map(value => {
        return Array.from(this.options.values()).filter(option =>
          option.text.toLowerCase().includes(value?.toString().toLowerCase())
        );
      })
    );
  }

  public ngOnDestroy(): void {
    this.onDestroy.next(undefined);
    this.onDestroy.complete();
  }

  /**
   * Checks if the request confirmed or not.
   *
   * @param response If the value is true, the request is confirmed, otherwise not.
   */
  public confirm(response: boolean): void {
    this.bannerContainer.clearAll();

    if (response) {
      if (this.data?.assignActionFactory !== undefined) {
        const assignObservable = this.data?.assignActionFactory(
          this.options.get(this.valueControl.value)?.value as string
        );

        /** If the provided action factory is incorrect we show error immediately */
        if (assignObservable === undefined || assignObservable.subscribe === undefined) {
          this.bannerContainer.showError('Cannot assign resource.', {
            closable: false,
          });

          return;
        }

        this.requestInFlight = true;

        assignObservable
          .pipe(
            tap(result => {
              this.dialogRef.close({ success: true, response: result });
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              this.latestNetworkResponse = errorResponse;

              this.bannerContainer.showApiError(errorResponse.error as Error, {
                closable: false,
              });

              return of(null);
            }),
            finalize(() => (this.requestInFlight = false))
          )
          .subscribe();
      } else {
        this.dialogRef.close(this.options.get(this.valueControl.value)?.value);
      }
    } else {
      /**
       * Closes the dialog without executing the action.
       * If a failed action was executed prior to closing it is passed back to the caller.
       */
      this.dialogRef.close({
        success: false,
        response: this.latestNetworkResponse,
      });
    }
  }
}
