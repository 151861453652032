import { format, round } from 'mathjs';

/**
 * Rounds the provided float to the given precision and returns the rounded result as a number string. If the precision is greater than the non-zero part
 * of the fraction, then extends it (with 0s).
 *
 * @param value The number that should be rounded.
 * @param precision The number of digits in the result after the precision point.
 * @param returnNumber Return value as well formatted string
 */
export function roundFloat(value: number, precision?: number, returnNumber?: false | undefined): string;
export function roundFloat(value: number, precision?: number, returnNumber?: true): number;
export function roundFloat(value: number, precision = 2, returnNumber?: boolean): string | number {
  if (!Number.isFinite(value) || !Number.isFinite(precision)) {
    throw new Error(`Both the received value (${value}) and precision (${precision}) must be a finite number!`);
  }

  if (precision < 0) {
    throw new Error(`Precision cannot be lower than zero!`);
  }

  return returnNumber
    ? round(value, precision)
    : format(round(value, precision), {
        notation: 'fixed',
        precision,
      });
}
