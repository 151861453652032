<mat-table mat-table [dataSource]="schedules" class="mat-elevation-z1">
  <ng-container matColumnDef="timeBlock">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Time block (local time)</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">{{ element.timeBlock }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="currentScheduleSekToPartner">
    <mat-header-cell mat-header-cell *matHeaderCellDef
      >Current schedule ({{ firstCurrentScheduleLabel }})</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">{{ element.firstCurrentSchedule }} MW</mat-cell>
  </ng-container>

  <ng-container matColumnDef="scheduleAfterTradeSekToPartner">
    <mat-header-cell mat-header-cell *matHeaderCellDef
      >Schedule after successful trade ({{ firstScheduleAfterTradeLabel }})</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">{{ element.firstScheduleAfterTrade }} MW</mat-cell>
  </ng-container>

  <ng-container matColumnDef="currentSchedulePartnerToSek">
    <mat-header-cell mat-header-cell *matHeaderCellDef
      >Current schedule ({{ secondCurrentScheduleLabel }})</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">{{ element.secondCurrentSchedule }} MW</mat-cell>
  </ng-container>

  <ng-container matColumnDef="scheduleAfterTradePartnerToSek">
    <mat-header-cell mat-header-cell *matHeaderCellDef
      >Schedule after successful trade ({{ secondScheduleAfterTradeLabel }})</mat-header-cell
    >
    <mat-cell mat-cell *matCellDef="let element">{{ element.secondScheduleAfterTrade }} MW</mat-cell>
  </ng-container>

  <mat-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-row>
  <mat-row mat-row *matRowDef="let element; columns: displayedColumns"> </mat-row>
</mat-table>
<h3
  class="st--alert"
  *ngIf="currentPartnerScheduleNotExists && !insufficientFilters && schedules && schedules.length === 0"
>
  No available schedule value.
</h3>
<h3 class="st--info" *ngIf="!currentPartnerScheduleNotExists && insufficientFilters">
  Current schedules table is empty because of insufficient filter setting!
</h3>
