import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Greater than validator. Value must be greater than the given number.
 *
 * @param minValue - The minimum value that the input must be greater than or equal to.
 * @param inclusive - Whether the validation should be inclusive (default) or exclusive.
 * @param errorMessage - The error message to show below the form when invalid. Optional.
 */
export function greaterThanValidator(minValue: number, inclusive: boolean = true, errorMessage?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value as number;

    const inclusiveCheck = inclusive && value < minValue;
    const exclusiveCheck = !inclusive && value <= minValue;

    if (value && (inclusiveCheck || exclusiveCheck)) {
      return {
        greaterThanInvalid: {
          greaterThan: minValue,
          errorMessage,
        },
      };
    }

    return null;
  };
}
