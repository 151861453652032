import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { formatInBudapestTimeZone } from './shared/utils/dates';

/**
 * This class extends the native date adapter to use hungarian display format
 * without setting the app locale to Hungarian. This is required becase we don't
 * want to display the date picker in Hungarian, but only the selected date.
 */
@Injectable()
export class HungarianNativeDateAdapter extends NativeDateAdapter {
  public override format(date: Date): string {
    return formatInBudapestTimeZone(date, 'yyyy-MM-dd');
  }

  public override parse(value: string): Date | null {
    if (/^[1-9][0-9]{3}-\d{2}-\d{2}$/.test(value)) {
      const [year, month, day] = value.split('-').map(value => parseInt(value));
      if (this.isValidDate(year, month, day)) {
        return new Date(value);
      }
    }
    return null;
  }

  /**
   * This check ensures, that dates which are considered valid
   * but when evaluated produce a different day are considered invalid.
   *
   * E.g.: 2024-02-30 is not a real date, but considered valid.
   * When evaluated, it becomes 2024-03-01
   */
  private isValidDate(year: number, month: number, day: number) {
    const d = new Date(`${year}-${month}-${day}`);

    return d.getFullYear() == year && d.getMonth() + 1 == month && d.getDate() == day;
  }

  public override getFirstDayOfWeek(): number {
    return 1;
  }
}
