import { DateTime } from 'luxon';
import { calculateInBudapestTimeZone } from './calculate-in-budapest-time-zone.util';
import { formatInBudapestTimeZone } from './format-in-budapest-time-zone.util';

/**
 * Generates a label representing the time range of a given time block in the Budapest time zone
 *
 * @param timeBlockStart - The start of the time block
 *
 * @returns The label representing the time range of the time block in the format 'HH:mm-HH:mm'
 *
 * @example
 * const timeBlockStart = new Date('2022-06-15T14:00:00.000+02:00');
 * generateHourLabelUtil(timeBlockStart); // Returns `14:00-15:00`
 */
export function generateHourLabelInBudapestTimeZone(timeBlockStart: Date): string {
  const hourStart = calculateInBudapestTimeZone(timeBlockStart, shiftedDate => shiftedDate.startOf('hour'));
  const nextTimeBlockStart = calculateInBudapestTimeZone(hourStart, shiftedDate => shiftedDate.plus({ hour: 1 }));

  /**
   * At DST off switch hour when the clock goes from 03:00 AM -> 02:00 AM, the hourDiff will be 0, thus
   * it must be corrected later, we add +1 hours to print the expected `02:00-03:00` label.
   */
  const hourDiff =
    DateTime.fromJSDate(nextTimeBlockStart).setZone('Europe/Budapest').hour -
    DateTime.fromJSDate(hourStart).setZone('Europe/Budapest').hour;
  const fixedNextTimeBlockStart = calculateInBudapestTimeZone(nextTimeBlockStart, shiftedDate =>
    shiftedDate.plus({ hour: hourDiff !== 0 ? 0 : 1 })
  );

  return `${formatInBudapestTimeZone(timeBlockStart, 'HH:mm')}-${formatInBudapestTimeZone(fixedNextTimeBlockStart, 'HH:mm')}`;
}
