<mat-drawer-container class="drawer-container" autosize
  ><mat-drawer #drawer class="help-drawer" mode="over" position="end">
    <app-main-layout>
      <section name="header">
        <div class="help-drawer-header">
          <button mat-icon-button (click)="helpDrawerService.toggle()">
            <mat-icon matListIcon>chevron_right</mat-icon>
          </button>
          <span>Help</span>
        </div>
      </section>
      <embed [src]="pdfUrl" />
    </app-main-layout> </mat-drawer
  ><ng-content></ng-content
></mat-drawer-container>
