import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error.dialog.component.html',
  styleUrls: ['./error.dialog.component.scss'],
})
export class ErrorDialogComponent {
  public content!: string;

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Error
  ) {
    if (data instanceof HttpErrorResponse) {
      if (data.status === 0) {
        this.content = `The API server is unreachable due to no internet connection or the server not responding.`;
      } else {
        this.content = (data.error as Error).message;
      }
    } else if (data?.message) {
      this.content = data.message;
    } else {
      this.content = JSON.stringify(this.data, null, 2);
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public reloadPage() {
    window.location.reload();
  }
}
