/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportOrderHistoryOwnQueryParameters, ReportOrderHistoryQueryParameters } from '../shared/interfaces/requests';
import { environment } from '../../environments/environment';
import { filterOutNullishProperties } from '../shared/utils';

@Injectable()
export class ReportsHupxService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Generate trade history report for with given query parameters.
   *
   * @param params Filter options.
   */
  public exportOrderHistoryReportXlsx(params: ReportOrderHistoryQueryParameters): Observable<Blob> {
    return this.httpClient.get(`${environment.apiBasePath}/v1/reports/hupx/order-history`, {
      params: filterOutNullishProperties({
        startDay: params.startDay,
        endDay: params.endDay,
        side: params.side,
        scenario: params.scenario,
        user_id: params.userId,
        tenant: params.tenant,
        tenant_filter: params.tenantFilter,
        collapse_orders: params.collapseOrders,
      }),
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  }

  /**
   * Generate trade history report of the current user with the given query parameters.
   *
   * @param params filter options
   */
  public exportOwnOrderHistoryReportXlsx(params: ReportOrderHistoryOwnQueryParameters): Observable<Blob> {
    return this.httpClient.get(`${environment.apiBasePath}/v1/reports/hupx/me/order-history`, {
      params: filterOutNullishProperties({
        startDay: params.startDay,
        endDay: params.endDay,
        side: params.side,
        scenario: params.scenario,
        collapse_orders: params.collapseOrders,
        limit: params.limit,
        offset: params.offset,
        tenant: params.tenant,
      }),
      responseType: 'blob',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  }
}
