import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sync-badge-component',
  templateUrl: './sync-badge.component.html',
  styleUrls: ['./sync-badge.component.scss'],
})
export class SyncBadgeComponent {
  /** The count of enabled sync switches. This is the value of the badge. */
  @Input() sendingStatesEnabledCount = 0;

  /** The length of enabled and disabled sync switches. */
  @Input() sendingStatesLength = 0;
}
