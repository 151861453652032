/* eslint-disable @typescript-eslint/no-unused-vars,
                  @typescript-eslint/no-empty-function */

import { Directive, ElementRef, HostListener, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { insertThousandSeparator, removeThousandSeparator } from '../utils';

@Directive({
  selector: 'input[appMatInputSeparated]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: AppThousandSeparatedDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppThousandSeparatedDirective),
      multi: true,
    },
  ],
})
export class AppThousandSeparatedDirective {
  private value!: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  /**
   * If the value is not null, this function formats the given value with thousand separator.
   *
   * @param value The raw value.
   */
  private formatValue(value: string | null) {
    if (value !== null) {
      this.elementRef.nativeElement.value = insertThousandSeparator(value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  /**
   * Reformats value to a number with no thousand separator.
   */
  private reformatValue() {
    const value = this.elementRef.nativeElement.value;
    this.value = removeThousandSeparator(value);
    if (value) {
      this.elementRef.nativeElement.value = this.value;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  /**
   * Passes input string to value as number format.
   *
   * @param value The input string.
   */
  @HostListener('input', ['$event.target.value'])
  public onInput(value: string) {
    this.value = removeThousandSeparator(value);
    this.onChange(this.value);
  }

  /**
   * Formats the value with thousand separator when input field is not on focus.
   */
  @HostListener('blur')
  public onBlur() {
    this.formatValue(this.value);
  }

  /**
   * Reformats value to a number with no thousand separator if the input field is on focus.
   */
  @HostListener('focus')
  onFocus() {
    this.reformatValue();
  }

  /**
   * Formats the default input values.
   *
   * @param value The default value from the input.
   */
  public writeValue(value: number) {
    this.value = value.toString();
    this.formatValue(this.value);
  }

  /**
   * Subscribes to input changes.
   */
  public registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  public registerOnTouched() {}

  public onChange(value: string): void {}
}
