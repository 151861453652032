/** The selectable auction types for a limit order. */
export enum AllowedAuction {
  /** Day ahead market auction type. */
  Dam = 'DAM',

  /** Intraday auction types. */
  Ida1 = 'IDA1',
  Ida2 = 'IDA2',
  Ida3 = 'IDA3',
}
