import { Injectable } from '@angular/core';
import { TenantService } from './tenant.service';
import { combineLatest, distinctUntilChanged, filter, forkJoin, map, Observable, switchMap } from 'rxjs';
import { RolesService } from '../modules/auth/services';
import { ScenarioDetails } from '../shared/interfaces';
import { CustomScheduleScenario } from '../modules/hupx-order/enums';
import { MasterDataService } from './master-data.service';
import { ScheduleType } from '../shared/enums';

/**
 * Responsible to provide trade scenario related operations.
 */
@Injectable()
export class ScenarioService {
  constructor(
    private readonly rolesService: RolesService,
    private readonly tenantService: TenantService,
    private readonly masterDataService: MasterDataService
  ) {}

  /**
   * Returns a stream of available scenarios for the selected tenant,
   * which refreshes at every tenant changes.
   */
  public listAvailableScenarios(): Observable<ScenarioDetails[]> {
    return combineLatest([
      this.tenantService.selectedTenantIdentifier.pipe(
        filter(tenantIdentifier => !!tenantIdentifier),
        distinctUntilChanged(),
        switchMap(tenantIdentifier =>
          forkJoin([
            this.masterDataService.listGroupedPodsForTenant(tenantIdentifier),
            this.masterDataService.listDomesticTradePartners({
              tenant: tenantIdentifier,
            }),
          ])
        )
      ),
    ]).pipe(
      map(([[{ productionPods, consumptionPods }, partners]]) => {
        const availableScenarios: ScenarioDetails[] = [];

        productionPods.forEach(pod => {
          if (pod.meteringPointId === 'HU001000-410U-SINERG1-BAEMK37') {
            availableScenarios.push({
              scenarioName: CustomScheduleScenario.SekAszkPodProduction,
              scheduleTypes: [ScheduleType.A01],
              productionPods: [pod],
              consumptionPods: [],
              domesticTradePartners: [],
            });
          } else if (pod.meteringPointId === 'HU001000-410U-SINERGY----KE--') {
            availableScenarios.push({
              scenarioName: CustomScheduleScenario.SekKePodProduction,
              scheduleTypes: [ScheduleType.A01],
              productionPods: [pod],
              consumptionPods: [],
              domesticTradePartners: [],
            });
          }
        });

        consumptionPods.forEach(pod => {
          if (pod.meteringPointId === 'HU001000-410U-FFMK37----FFSUM') {
            availableScenarios.push({
              scenarioName: CustomScheduleScenario.AlteoRetailConsumption,
              scheduleTypes: [ScheduleType.A04],
              productionPods: [],
              consumptionPods: [pod],
              domesticTradePartners: [],
            });
          }
        });

        partners.forEach(partner => {
          if (partner.eic === '15X-KATENERGIA-V') {
            availableScenarios.push({
              scenarioName: CustomScheduleScenario.KatDomestic,
              scheduleTypes: [ScheduleType.A02],
              productionPods: [],
              consumptionPods: [],
              domesticTradePartners: [partner],
            });
          }
        });

        const scheduleTypes = Object.values(ScheduleType).filter(scheduleType => {
          switch (scheduleType) {
            case ScheduleType.A01:
              return productionPods.length > 0;
            case ScheduleType.A02:
              return partners.length > 0;
            case ScheduleType.A04:
              return consumptionPods.length > 0;
            default:
              return false;
          }
        });

        if (scheduleTypes.length > 0) {
          availableScenarios.push({
            scenarioName: CustomScheduleScenario.Custom,
            scheduleTypes,
            productionPods,
            consumptionPods,
            domesticTradePartners: partners,
          });
        }

        // if (userRoles.includes(Role.Administrator)) {
        //   availableScenarios.push({
        //     scenarioName: CustomScheduleScenario.None,
        //     scheduleTypes: [],
        //     productionPods: [],
        //     consumptionPods: [],
        //     domesticTradePartners: [],
        //   });
        // }

        return availableScenarios;
      })
    );
  }
}
